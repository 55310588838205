import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import CsvDownload from 'react-json-to-csv'

class download_responses extends Component{
	constructor() {
		super();
	    this.state = {
	    	task: '',
	    	start_datetime: '',
	    	end_datetime: '',
			task_list: [],
			gp_responses: [],
			show_csv: false,
			loading: false,
			logged_in: false,
		};
		
		if(typeof ReactSession.get("username") !== 'undefined' && typeof ReactSession.get("access_token") !== 'undefined'){
			this.state.logged_in= true;
			this.state.username = ReactSession.get("username");
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.load_active_tasks = this.load_active_tasks.bind(this);
		this.task_options = this.task_options.bind(this);
	}

	componentDidMount(){
		this.load_active_tasks();
	}

	load_active_tasks = () =>{
		var access_token = 'Bearer ' + ReactSession.get("access_token");

		axios.get(global.config.apiGateway.URL+"/admin/tasks",
			{ headers: {Authorization: access_token } })
		.then((response) =>{
			var task_list = response.data;
			var arr = [];
			for (let index = 0; index < task_list.length; index++) {
				var task = task_list[index];
				task.title = task.title.trim();
				arr.push(task);
				
			}
			this.setState({
				task: arr[0],
				task_list: arr,
			});
		})
		.catch((err)=>{
			console.error(String(err));
		});
	}
	
	task_options = () => {
		var options = '';
		if(this.state.task_list.length){
			options = this.state.task_list.map((task_list,index) => {
				return (
					<option value={task_list["id"]} id={task_list["id"]}>{task_list.title} {task_list.status === true?"Active":"" } ({task_list["id"]}) </option>
				);
			});
		}
		return options;

	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val,
			show_csv: false,
			gp_responses: []
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();

		//check validity of datetime inputs
		if (document.getElementById('start_datetime').validity.valid === false)
		{
			alert("Please select a starting Date");
			return;
		}
		if (document.getElementById('end_datetime').validity.valid === false)
		{
			alert("Please select an End Date")
			return;
		}

		this.setState({
			loading: true
		})

		// convert timestamp into utc for sending to backend
		var momentTz = require('moment-timezone');
		var start_dt = momentTz.tz(this.state.start_datetime, "YYYY-MM-DDTHH:mm", "Asia/Kolkata").utc();
		start_dt = start_dt.format("YYYY-MM-DD HH:mm:ss");
		var end_dt = momentTz.tz(this.state.end_datetime, "YYYY-MM-DDTHH:mm", "Asia/Kolkata").utc();
		end_dt = end_dt.format("YYYY-MM-DD HH:mm:ss");

		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var params;
		params = {
					"task_id": document.getElementById('task').value,
					"start_date": start_dt,
					"end_date": end_dt
		};
		var data = [];
		axios.get(global.config.apiGateway.URL+"/admin/tasks/instances/report",
		{
			headers: {Authorization: access_token},
			params: params
		})
		.then((response) => {
			console.log(response);
			if(response.status === 200){
				data = response.data;
				this.setState({
					gp_responses: data.gp_responses,
					show_csv: true,
					loading: false
				});
				alert('Responses Fetched');
			}
			else if(response.status === 204){
				this.setState({
					loading:false
				})
				alert('No Responses for this Task');
			}
		})
		.catch((err) => {
			console.log(err.response);
			this.setState({
				loading:false
			})
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Task not found');
			}
		});
	}

	render(){
		if(!this.state.logged_in){
			return(
				<div>
					<h5>Not logged in !</h5>
				</div>
			);
		}
		else{
			return(
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h3 style={{"margin":"40px", "textAlign":"center"}}>Download Task Responses</h3>
						</div>
					</div>
					<form className="form-horizontal row" onSubmit={this.handleSubmit}>
						<div className="form-group">
						  <label className="control-label col-md-3" htmlFor="task" style={{"textAlign":"left"}}>Task :</label>
						  <div className="col-md-9">
							  <select required type="number" className="form-control" id="task" name="task" onChange={this.handleChange} 
							//   value={this.state.task}
							  >
								{this.task_options()}
							  </select>
						   </div>
						</div>
						<div className="form-group">
						  <label className="control-label col-md-3" htmlFor="start_datetime" style={{"textAlign":"left"}}>Start DateTime:</label>
						  <div className="col-md-9">          
							<input type="datetime-local" className="form-control" id="start_datetime" name="start_datetime" min="2020-01-01T00:00" max="2030-01-01T00:00" onChange={this.handleChange} value={this.state.start_datetime} required/>
						  </div>
						</div>
						<div className="form-group">
						  <label className="control-label col-md-3" htmlFor="end_datetime" style={{"textAlign":"left"}}>End DateTime:</label>
						  <div className="col-md-9">
							  <input type="datetime-local" className="form-control" id="end_datetime" name="end_datetime" min="2020-01-01T00:00" max="2030-01-01T00:00" onChange={this.handleChange} value={this.state.end_datetime} required/>
						  </div>
						</div>
					  </form>
					<div className="form-horizontal row">
						<div className="form-group">        
							<div className="col-md-offset-2 col-md-10">
								{!this.state.show_csv ?
									<button className="btn btn-default" style={{"float":"right"}} onClick={this.handleSubmit} disabled={this.state.loading}>
										{this.state.loading?
											"Fetching Responses"
											:
											"Fetch Responses"
										}
									</button>
									:
									<div className="download" style={{"float":"right"}}>
										<CsvDownload 
											data={this.state.gp_responses}
											filename={"Response_"+this.state.task+".csv"}
											className="btn btn-default"
										>
											Download CSV
										</CsvDownload>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			);
		}	
	}
}

export default download_responses;