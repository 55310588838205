import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { ReactSession } from "react-client-session";
import axios from "axios";
import "./kycList.css";
import * as ReactBootStrap from "react-bootstrap";
import { Link } from "react-router-dom";
import Switch from "./SwitchList";
import ReactPlayer from "react-player";
import Select from "react-select";
import KYC_Card from "../kyc_details/kycDetails";

let curPage;
const KycList = () => {
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [value, setValue] = useState(false);
  const [sort, setSort] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [startAge, setStartAge] = useState(0);
  const [endAge, setEndAge] = useState(100);
  const [location, setLocation] = useState("");
  const [kycState, setKycState] = useState();
  const [activeState, setActiveState] = useState();

  let limit = 100;
  // let curPage;

  var access_token = "Bearer " + ReactSession.get("access_token");

  useEffect(() => {
    // var access_token = "Bearer " + ReactSession.get("access_token");

    const getData = async () => {
      const res = await fetch(
        global.config.apiGateway.URL +
          `/admin/users/kycs?page=1&limit=${limit}`,
        {
          headers: {
            Authorization: access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      console.log(data);
      const resultList = data.data;
      const total = data.total_results;
      setpageCount(Math.ceil(total / limit));

      // const sorted = resultList

      setItems(resultList);
      console.log(resultList);
    };
    getData();
  }, [limit]);

  //   console.log(items);

  const fetchData = async (currentPage) => {
    const res = await fetch(
      global.config.apiGateway.URL +
        `/admin/users/kycs?page=${currentPage}&limit=${limit}`,
      {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    const resultList = data.data;

    console.log(resultList);
    return resultList;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    let currentPage = data.selected + 1;
    curPage = currentPage;
    const dataFormServer = await fetchData(currentPage);
    setItems(dataFormServer);
  };

  const handleSort = (e) => {
    console.log(e.target.value);
    setSort(e.target.value);
  };

  const handleStartAge = (e) => {
    console.log(e.target.value);
    setStartAge(e.target.value);
  };

  const handleEndAge = (e) => {
    console.log(e.target.value);
    setEndAge(e.target.value);
  };

  const handleLocation = (e) => {
    console.log(e.label);
    setLocation(e.label);
  };

  const handleKycChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "approved") {
      setKycState(true);
    } else if (e.target.value === "rejected") {
      setKycState(false);
    }
  };

  const handleActiveChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "active") {
      setActiveState(true);
    } else if (e.target.value === "notactive") {
      setActiveState(false);
    }
  };

  // const sortToNewest = () => {
  const sortToNewest = async () => {
    const res = await fetch(
      global.config.apiGateway.URL +
        `/admin/users/kycs?page=1&limit=${limit}&sort=time+desc`,
      {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);
    const resultList = data.data;
    const total = data.total_results;
    setpageCount(Math.ceil(total / limit));

    // const sorted = resultList

    setItems(resultList);
    console.log(resultList);
  };
  // getData();
  // };

  // const sortToOldest = () => {
  const sortToOldest = async () => {
    const res = await fetch(
      global.config.apiGateway.URL +
        `/admin/users/kycs?page=1&limit=${limit}&sort=time+asc`,
      {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);
    const resultList = data.data;
    const total = data.total_results;
    setpageCount(Math.ceil(total / limit));

    // const sorted = resultList

    setItems(resultList);
    console.log(resultList);
  };
  // getData();
  // };

  const applyFilter = () => {
    if (
      startAge !== 0 &&
      endAge !== 100 &&
      location === "" &&
      kycState === undefined &&
      activeState === undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge !== 0 &&
      endAge !== 100 &&
      location !== "" &&
      kycState === undefined &&
      activeState === undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}&state=${location}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge !== 0 &&
      endAge !== 100 &&
      location === "" &&
      kycState !== undefined &&
      activeState === undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}&kyc=${kycState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge !== 0 &&
      endAge !== 100 &&
      location === "" &&
      kycState === undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge !== 0 &&
      endAge !== 100 &&
      location !== "" &&
      kycState !== undefined &&
      activeState === undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}&state=${location}&kyc=${kycState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge !== 0 &&
      endAge !== 100 &&
      location !== "" &&
      kycState === undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}&state=${location}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge !== 0 &&
      endAge !== 100 &&
      location === "" &&
      kycState !== undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}&kyc=${kycState}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge === 0 &&
      endAge === 100 &&
      location !== "" &&
      kycState === undefined &&
      activeState === undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&state=${location}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge === 0 &&
      endAge === 100 &&
      location !== "" &&
      kycState !== undefined &&
      activeState === undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&state=${location}&kyc=${kycState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge === 0 &&
      endAge === 100 &&
      location !== "" &&
      kycState === undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&state=${location}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge === 0 &&
      endAge === 100 &&
      location !== "" &&
      kycState !== undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&state=${location}&kyc=${kycState}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge === 0 &&
      endAge === 100 &&
      location === "" &&
      kycState !== undefined &&
      activeState === undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&kyc=${kycState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge === 0 &&
      endAge === 100 &&
      location === "" &&
      kycState !== undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&kyc=${kycState}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge === 0 &&
      endAge === 100 &&
      location === "" &&
      kycState === undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    } else if (
      startAge !== 0 &&
      endAge !== 100 &&
      location !== "" &&
      kycState !== undefined &&
      activeState !== undefined
    ) {
      const getData = async () => {
        const res = await fetch(
          global.config.apiGateway.URL +
            `/admin/users/kycs?page=1&limit=${limit}&startAge=${startAge}&endAge=${endAge}&state=${location}&kyc=${kycState}&isActive=${activeState}`,
          {
            headers: {
              Authorization: access_token,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();
        console.log(data);
        const resultList = data.data;
        const total = data.total_results;
        setpageCount(Math.ceil(total / limit));
        setItems(resultList);
        console.log(resultList);
      };
      getData();
    }
    setStartAge(0);
    setEndAge(100);
    setLocation("");
    setKycState(undefined);
    setActiveState(undefined);
    setFilterActive(false);
  };

  const ageOptions = [
    18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
    37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
    56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
    75, 76, 77, 78, 79, 80,
  ];
  const ageValues = ageOptions.map((ageOption, index) => {
    return (
      <option value={ageOption} key={index}>
        {ageOption}
      </option>
    );
  });

  const handleVideoReject = (id) => {
    if (!window.confirm("Are your sure to reject this video?")) return;
    var formdata = new FormData();
    formdata.append("user_id", id);
    axios({
      method: "put",
      url:
        global.config.apiGateway.URL + "/admin/users/" + id + "/video-deletion",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          alert("Video Rejected successfully");
        }
        // window.location.reload()
        const handleRefresh = async (data) => {
          const dataFormServer = await fetchData(curPage);
          setItems(dataFormServer);
        };
        handleRefresh();
      })
      .catch((error) => {
        console.error(String(error));
      });
    console.log(id);
  };

  return (
    <div>
      <div className="kyclist-header">
        {/* <select
          className="sort-select"
          defaultValue="Sort By Time"
          onChange={handleSort}
        >
          <option disabled value="Sort By Time">
            Sort By Time
          </option>
          <option value="AscendingTime">Sort By Time - Asc</option>
          <option value="DescendingTime">Sort By Time - Desc</option>
        </select> */}
        <button className="sort-button-newest" onClick={sortToNewest}>
          Newest
        </button>
        <button className="sort-button-oldest" onClick={sortToOldest}>
          Oldest
        </button>
        <button className="filter-button" onClick={() => setFilterActive(true)}>
          Filter
        </button>
        {filterActive && (
          <div className="filter-box">
            <div className="filter-box-age">
              <p style={{ color: "#989898" }}>Age</p>
              <div>
                <select
                  className="filter-box-age-select"
                  onChange={handleStartAge}
                >
                  <option selected="selected" disabled>
                    00
                  </option>
                  {ageValues}
                </select>
                &nbsp;&nbsp; -&nbsp;&nbsp;
                <select
                  className="filter-box-age-select"
                  onChange={handleEndAge}
                >
                  <option selected="selected" disabled>
                    00
                  </option>

                  {ageValues}
                </select>
              </div>
            </div>
            <hr></hr>
            <div className="filter-box-location">
              <p style={{ color: "#989898" }}>Location</p>
              <Select
                name="segment"
                options={ReactSession.get("states")}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleLocation}
              />
            </div>
            <hr></hr>
            <div>
              <p>KYC Status</p>
              <div className="filter-box-status-choice">
                <p style={{ color: "#989898" }}>Donne</p>
                <input
                  className="filter-box-checkbox"
                  type="checkbox"
                  value="approved"
                  onChange={handleKycChange}
                />
              </div>
              <div className="filter-box-status-choice">
                <p style={{ color: "#989898" }}>Not Done</p>
                <input
                  className="filter-box-checkbox"
                  type="checkbox"
                  value="rejected"
                  onChange={handleKycChange}
                />
              </div>
            </div>
            <hr></hr>
            <div>
              <p>Active Status</p>
              <div className="filter-box-status-choice">
                <p style={{ color: "#989898" }}>Active</p>
                <input
                  className="filter-box-checkbox"
                  type="checkbox"
                  value="active"
                  onChange={handleActiveChange}
                />
              </div>
              <div className="filter-box-status-choice">
                <p style={{ color: "#989898" }}>Not Active</p>
                <input
                  className="filter-box-checkbox"
                  type="checkbox"
                  value="notactive"
                  onChange={handleActiveChange}
                />
              </div>
            </div>
            <hr></hr>
            <button onClick={applyFilter} className="filter-box-apply-button">
              Apply
            </button>
            <button
              onClick={() => {
                setFilterActive(false);
              }}
              className="filter-box-cancel-button"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      <ReactBootStrap.Table striped bordered hover>
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>User ID</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Age</th>
            <th>SignUp Date</th>
            <th>Is active</th>
            <th>KYC Status</th>
            <th>District</th>
            <th>State</th>
            <th>Total Reward</th>
            <th>Introductory Video</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            let activeCheck;
            let kycCheck;
            if (item.is_active) {
              activeCheck = "Active";
            } else {
              activeCheck = "Active";
            }
            if (item.kyc) {
              kycCheck = "Done";
            } else {
              kycCheck = "Not Done";
            }

            const toggle_active_status = () => {
              setValue(!item.is_active);
              if (window.confirm("Are you sure, you want to continue?")) {
                var formdata = new FormData();
                formdata.append("is_active", !item.is_active);
                axios
                  .put(
                    global.config.apiGateway.URL +
                      "/admin/users/" +
                      item.user_id +
                      "/status",
                    formdata,
                    {
                      headers: { Authorization: access_token },
                    }
                  )
                  .then((response) => {
                    // console.log(response.data);
                  })
                  .catch((error) => {
                    console.error(String(error));
                  });
              } else {
                setValue(item.is_active);
              }
              {
                /* setTimeout(() => {
                window.location.reload(false);
              }, 50); */
              }
              const handleRefresh = async (data) => {
                const dataFormServer = await fetchData(curPage);
                setItems(dataFormServer);
              };
              handleRefresh();
              setTimeout(handleRefresh, 150);
            };

            return (
              <tr key={index}>
                <td>{item.user_id}</td>

                <td>
                  <Link to={"/partner_profile/" + item.user_id}>
                    {item.first_name + " " + item.last_name}
                  </Link>
                </td>
                <td>{item.phone_no}</td>
                <td>{item.age}</td>
                <td>{item.signup_date}</td>
                {/* <td>{activeCheck}</td> */}
                <td>
                  <Switch
                    isOn={item.is_active}
                    id={item.user_id}
                    handleToggle={toggle_active_status}
                  />
                </td>
                <td>
                  <Link to={"/kyc_card/" + item.user_id + "/" + kycCheck}>
                    {kycCheck}
                  </Link>
                </td>
                <td>{item.district}</td>
                <td>{item.state}</td>
                <td>{item.total_reward}</td>
                <td>
                  {item.introductory_video !== null ? (
                    <div>
                      <ReactPlayer
                        url={item.introductory_video}
                        controls
                        height="150px"
                        width="250px"
                      />
                      <div className="reject-button-container">
                        <button
                          onClick={() => handleVideoReject(item.user_id)}
                          className="reject-button"
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p>No Video</p>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </ReactBootStrap.Table>
      <div>
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default KycList;
