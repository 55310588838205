import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';

class FormFieldOptionForm extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	option_text: '',
	    	position: this.props.option_pos,
	    	display_form: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.hide_or_show_form = this.hide_or_show_form.bind(this);
	}

	componentDidUpdate(prevProps) {
	    if(this.props.option_pos !== prevProps.option_pos) 
		{
		    this.setState({
		    	option_text: '',
		    	position: this.props.option_pos,
		    	display_form: false
			});
		 }
	} 

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		var option_text = this.state.option_text.trim();
		this.setState({
			option_text : option_text,
		});
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('form_field_id', this.props.form_field_id);
		formdata.append('option_text', option_text);
		formdata.append('position', this.state.position);

		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/admin/form-fields/"+this.props.form_field_id+"/options",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(response);
			if(response.status === 200){
				alert('Form Field Option Added');
				this.props.reload();
			}
		})
		.catch((err) => {
			console.error(err.response);
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Form Field not found');
			}
		});
	}

	hide_or_show_form = () => {
		if(this.state.display_form)
			this.setState({display_form: false});
		else
			this.setState({display_form: true});
	}

	render(){
		if(this.state.display_form){
			return(
				<div className="col-md-12 row"  style={{"marginTop":"10px"}}>
					<div className="col-md-2">
						<button className="btn" onClick={this.hide_or_show_form}>Add Option</button>
					</div>
					<form className="form-inline col-md-10" onSubmit={this.handleSubmit}>
					    <div className="form-group">
					      <label className="control-label" htmlFor="option_text">Option Text : </label>        
					        <input required type="text" style={{"margin":"5px"}} className="form-control" id="option_text" name="option_text" onChange={this.handleChange} value={this.state.option_text}/>		
					    </div>
					    <div className="form-group">
					    	<div className="col-md-2">
					    	</div>
					    </div>
					    <div className="form-group">
					      <label className="control-label" htmlFor="position">Position : </label>        
					        <input disabled type="number" style={{"margin":"5px"}} className="form-control" id="position" name="position" onChange={this.handleChange} value={this.state.position}/>
					    </div>
					    <div className="form-group">
					    	<div className="col-md-2">
					    	</div>
					    </div>
					    <div className="form-group">        
					        <button type="submit" className="btn btn-default">Submit</button>
					    </div>
				  </form>
				</div>
			);
		}
		else{
			return(
				<div className="col-md-2" style={{"marginTop":"10px"}}>
					<button className="btn" onClick={this.hide_or_show_form}>Add Option</button>
				</div>
			);
		}	
	}
}

export default FormFieldOptionForm;