import { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";

import axios from "axios";

const useSearch = (text, pageNumber) => {
  const [suggestions, setSuggestions] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;

    // console.log("Options Fetched from API");
    var access_token = "Bearer " + ReactSession.get("access_token");

    if (text.length === 0) {
      setSuggestions([]);
      return;
    }

    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/users?query=" +
          text +
          "&page=" +
          pageNumber +
          "&limit=" +
          10,
        {
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
          headers: { Authorization: access_token },
        }
      )
      .then((res) => {
        console.log(res.data);
        var resultData = res.data;
        let tempList = [];

        var resultList = resultData.results;
        for (var i = 0; i < resultList.length; i++) {
          tempList.push({
            name: resultList[i].first_name + " " + resultList[i].last_name,
            userId: resultList[i].user_id,
          });
        }
        if (pageNumber>1) {
          setSuggestions((prevSug) => {
            return [...new Set([...prevSug, ...tempList])];
          });
        } else {
          setSuggestions(tempList);
        }
        // setSuggestions(tempList)
        // setHasMore(resultList.length > 0);
        setHasMore(resultData.next_page);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        setError(true);
      });
    return () => cancel();
  }, [text, pageNumber]);

  return { loading, error, suggestions, hasMore };
};

export default useSearch;
