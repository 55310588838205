import React from 'react';
import FormFieldOptionForm from './form_field_option_form';

const FormFieldList = (props) => {
	var data;
	var form_fields = props.form_fields;
	if(form_fields.length){
	data = form_fields.map((form_field) => {
			var options_required = form_field.type === 'radio' || form_field.type === 'checkbox';
			if(options_required){
				var option_pos = 0;
				var options = form_field.options.map((option)=>{
					if(option.position>option_pos){
						option_pos = option.position;
					}
					return (
						<div className="col-md-12 row" key={option.id}>
							<div className="col-md-4">
								<b>Option</b>
							</div>
							<div className="col-md-4">
								Position : {option.position}
							</div>
							<div className="col-md-4">
								 {option.option_text}
							</div>
						</div>
					);
				});
			}
			return(
				<div className="col-md-12 row list-group-item" key={form_field.id}>
					<div className="col-md-3">
						Position : {form_field.position}
					</div>
					<div className="col-md-3">
						Created By : {form_field.created_by}
					</div>
					<div className="col-md-3">
						Type : {form_field.type}
					</div>
					<div className="col-md-3">
						Active : {form_field.is_active
								  ? "True"
								  : "False"
								  }
					</div>
					<div className="col-md-12" style={{"margin-top": "10px"}}>
						Question : {form_field.question_text}
					</div>
					<div className="col-md-3" style={{"margin-top": "10px"}}>
						Placeholder : {form_field.placeholder_text !== null ? form_field.placeholder_text : <h5 style={{"color": "red", "display":"inline"}}> No Placeholder</h5>}
					</div>
					<div className="col-md-3" style={{"margin-top": "10px"}}>
						Validation Required : {form_field.validation_required
												? "True"
								  				: "False"
												}
					</div>
					<div className="col-md-3" style={{"margin-top": "10px"}}>
						Required : {form_field.is_required
									? "True"
								  	: "False"}
					</div>
					<div className="col-md-3" style={{"margin-top": "10px"}}>
						Unique Responses : {form_field.check_uniqueness
									? "True"
								  	: "False"}
					</div>
					<div className="col-md-12">
						{form_field.id===props.amount_form_field
							? <p style={{"color":"green"}}>Amount Form Field</p>
							: <button className="btn btn-default btn-xs" onClick={()=>props.assign(form_field.id)}>Assign as Amount Form Field</button>
						}
					</div>
					{ options_required &&
						options
					}
					{ options_required &&
						<FormFieldOptionForm form_field_id={form_field.id} option_pos={option_pos+1} reload={props.reload}/>
					}
				</div>
			);
		});
	}
	else{
		data = <div className="col-md-12">
						<h4 style={{"color": "red"}}>No Questions found!</h4>
					 </div>
	}
	return (
		<div className="list-group">
			{data}
		</div>
	);
}

export default FormFieldList;