import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";

class JobInstanceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      job_id: props.match.params.job_id,
      job_instances: [],
      logged_in: false,
      username: "",
      show_pending: false,
      show_approved: false,
      searchTerm: "",
      pageCount: 0,
    };

    if (typeof ReactSession.get("username") !== "undefined") {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }
    this.handleChange = this.handleChange.bind(this);
    this.fetch_job_instances = this.fetch_job_instances.bind(this);
    this.generate_job_instance_list =
      this.generate_job_instance_list.bind(this);
    this.hide_or_show_pending = this.hide_or_show_pending.bind(this);
    this.hide_or_show_approved = this.hide_or_show_approved.bind(this);
    this.hide_or_show_all = this.hide_or_show_all.bind(this);
    this.count_jobs = this.count_jobs.bind(this);
  }

  limit = 10;

  handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    console.log(val);

    this.setState({
      [nam]: val,
    });
  };

  componentDidMount() {
    if (this.state.logged_in) this.fetch_job_instances();
  }

  fetch_job_instances = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    var params;
    if (this.state.job_id) {
      params = {
        fetchBy: "job_id",
        id: this.state.job_id,
      };
    } else {
      params = {
        fetchBy: "all",
      };
    }

    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/jobs/instances?page=1&limit=" +
          this.limit,
        {
          headers: { Authorization: access_token },
          params: params,
        }
      )
      .then((response) => {
        var data = response.data.data;
        const total = response.data.total_results;
        this.setState({ pageCount: Math.ceil(total / this.limit) });
        console.log(total);
        this.setState({ job_instances: data });
      })
      .catch((error) => {
        console.error(String(error));
      });
  };


    fetchData = (currentPage) => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    var params;
    if (this.state.job_id) {
      params = {
        fetchBy: "job_id",
        id: this.state.job_id,
      };
    } else {
      params = {
        fetchBy: "all",
      };
    }

    axios
      .get(
        global.config.apiGateway.URL +
        `/admin/jobs/instances?page=${currentPage}&limit=${this.limit}`,
        {
          headers: { Authorization: access_token },
          params: params,
        }
      )
      .then((response) => {
        var data = response.data.data;
        const total = response.data.total_results;
        this.setState({ pageCount: Math.ceil(total / this.limit) });
        console.log(total);
        this.setState({ job_instances: data });
      })
      .catch((error) => {
        console.error(String(error));
      });
    
      return this.state.job_instances;
  };

  handlePageClick = async (data) => {
    // console.log(data.selected);
    let currentPage = data.selected + 1;
    // curPage = currentPage;
    const dataFormServer = this.fetchData(currentPage);
    // setItems(dataFormServer);
    this.setState({job_instances: dataFormServer});
  };


  generate_job_instance_list = () => {
    var job_instance_list = "";
    if (this.state.job_instances.length) {
      job_instance_list = this.state.job_instances
        .filter((job) => {
          if (this.state.searchTerm === "") {
            return job;
          } else if (
            job.user_name
              .toLowerCase()
              .includes(this.state.searchTerm.toLowerCase()) ||
            job.user_mobile_no
              .toLowerCase()
              .includes(this.state.searchTerm.toLowerCase())
          ) {
            return job;
          }
          return null;
        })
        .map((job) => {
          let check;

          if (job.number_approved > 0) {
            check = "Approved : " + job.number_approved;
          } else if (job.number_rejected > 0) {
            check = "Rejected : " + job.number_rejected;
          } else if (job.number_pending) {
            check = "Pending : " + job.number_pending;
          } else if (job.number_incomplete > 0) {
            check = "Incomplete : " + job.number_incomplete;
          }

          if (
            (this.state.show_pending && job.number_pending !== 0) ||
            (!this.state.show_pending && !this.state.show_approved) ||
            (this.state.show_approved &&
              job.number_pending === 0 &&
              job.number_rejected === 0 &&
              job.number_incomplete === 0 &&
              job.number_approved !== 0)
          ) {
            return (
              <div
                className="col-md-12 row list-group-item"
                key={job.id}
                style={{
                  background:
                    job.number_approved !== 0 &&
                    job.number_pending === 0 &&
                    job.number_rejected === 0 &&
                    job.number_incomplete === 0
                      ? "#a2ebb5"
                      : job.number_rejected !== 0 &&
                        job.number_approved === 0 &&
                        job.number_pending === 0 &&
                        job.number_incomplete === 0
                      ? "#ffa991"
                      : job.number_rejected === 0 &&
                        job.number_approved === 0 &&
                        job.number_pending === 0 &&
                        job.number_incomplete !== 0
                      ? "#ebebeb"
                      : "#ffe9bd",
                }}
              >
                <div className="row" style={{ "padding-top": "10px" }}>
                  <div className="col-md-4">
                    Job Instance ID : {job.id} <br></br> <br></br>
                    Start Time : {moment(job.start_time).local().format("LLL")}
                  </div>
                  <div className="col-md-4">
                    GP Name :{" "}
                    <Link to={"/partner_profile/" + job.user_id}>
                      {job.user_name + " " + "(" + job.user_mobile_no + ")"}
                    </Link>{" "}
                    <br></br> <br></br>
                    End Time :
                    {job.end_time
                      ? moment(job.end_time).local().format("LLL")
                      : ""}
                  </div>
                  <div className="col-md-2">
                    Job : <Link to={"/job/" + job.job_id}>{job.title}</Link>
                  </div>
                  <div className="col-md-2">
                    <Link to={"/job_instance/" + job.id}>
                      <b>VIEW</b>
                    </Link>{" "}
                    <br></br> <br></br>
                    {check}
                  </div>
                </div>
                <div
                  className="row"
                  style={{ "margin-top": "10px", "padding-bottom": "10px" }}
                ></div>
              </div>
            );
          } else {
            return null;
          }
        });
    } else {
      job_instance_list = (
        <div>
          <h3>No job instances to show</h3>
        </div>
      );
    }
    return job_instance_list;
  };

  count_jobs = () => {
    var approved = 0;
    var all = 0;
    var pending = 0;
    this.state.job_instances.map((job) => {
      // if (job.number_pending === 0 && job.number_rejected === 0) {
      //   approved++;
      // }
      if (job.number_approved !== 0) {
        approved++;
      }
      if (job.number_pending !== 0) {
        pending++;
      }
      all++;
      return null;
    });
    return {
      all: all,
      approved: approved,
      pending: pending,
    };
  };

  hide_or_show_pending = () => {
    this.setState({
      show_pending: !this.state.show_pending,
      show_approved: false,
    });
  };

  hide_or_show_approved = () => {
    this.setState({
      show_approved: !this.state.show_approved,
      show_pending: false,
    });
  };

  hide_or_show_all = () => {
    this.setState({
      show_pending: false,
      show_approved: false,
    });
  };
  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    }
    var job_instance_list = this.generate_job_instance_list();
    const { all, pending, approved } = this.count_jobs();
    return (
      <div className="row">
        <div className="col-md-12 page-header text-center">
          {this.state.job_id ? (
            <div>
              <h2>
                <small>
                  <Link to={"/job/" + this.state.job_id}>
                    Job ID - {this.state.job_id}
                  </Link>
                </small>{" "}
                Job Instances
              </h2>
              <input
                name="searchTerm"
                type="text"
                placeholder="Gram Pracharak Name"
                onChange={this.handleChange}
                value={this.state.searchTerm}
                style={{ "padding-left": "10px", float: "right" }}
              />
            </div>
          ) : (
            <div>
              <h2>Job Instances</h2>
              <input
                name="searchTerm"
                type="text"
                placeholder="Gram Pracharak Name"
                onChange={this.handleChange}
                value={this.state.searchTerm}
                style={{ "padding-left": "10px", float: "right" }}
              />
            </div>
          )}
        </div>
        <div className="col-md-12" style={{ marginBottom: "10px" }}>
          <div className="col-md-4" style={{ display: "flex" }}>
            <h5>Total Job Instances : {all}</h5>
            <button
              className="btn btn-xs"
              onClick={this.hide_or_show_all}
              style={{ marginLeft: "5px" }}
            >
              Show All
            </button>
          </div>
          <div className="col-md-4" style={{ display: "flex" }}>
            <h5>Job Instances Pending for Approval : {pending}</h5>
            <button
              className="btn btn-xs"
              onClick={this.hide_or_show_pending}
              style={{ marginLeft: "5px" }}
            >
              Show Pending
            </button>
          </div>
          <div className="col-md-4" style={{ display: "flex" }}>
            <h5>Approved Job Instances : {approved}</h5>
            <button
              className="btn btn-xs"
              onClick={this.hide_or_show_approved}
              style={{ marginLeft: "5px" }}
            >
              Show Approved
            </button>
          </div>
        </div>
        {job_instance_list}
        <div>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}

export default JobInstanceList;
