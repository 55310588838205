import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';

class TaskForm extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	title: '',
	    	description: '',
	    	fixed_amount: '',
	    	form_details: '',
	    	form_intro: '',
	    	form_title: '',
	    	stage: this.props.stage,
	    	approval_required: true,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}

	handleCheck = (e) => {
		this.setState({
			approval_required: !this.state.approval_required
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		var title = this.state.title.trim();
		var form_title = this.state.form_title.trim();
		this.setState({
			title : title,
			form_title: form_title,
		});
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('job_id', this.props.job_id);
		formdata.append('title', title);
		formdata.append('description', this.state.description);
		formdata.append('fixed_amount', this.state.fixed_amount);
		formdata.append('form_title', form_title);
		formdata.append('form_intro', this.state.form_intro);
		formdata.append('form_details', this.state.form_details);
		formdata.append('stage', this.state.stage);
		formdata.append('approval_required', this.state.approval_required);
		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/admin/jobs/"+this.props.job_id+"/tasks",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(response);
			if(response.status === 200){
				alert('Task Created');
				//window.location.reload(false);
				this.props.reload();
				this.props.hide();
			}
		})
		.catch((err) => {
			console.error(String(err));
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Job not found');
			}
		});
	}

	render(){
		return(
			<div className="col-md-12">
				<div className="col-md-3 row">
					<div className="col-md-12">
						<h3>Create a Task</h3>
					</div>
				</div>
				<form className="form-horizontal col-md-9" onSubmit={this.handleSubmit}>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="title">Title:</label>
				      <div className="col-md-10">
				        <input required type="text" className="form-control" id="title" name="title" onChange={this.handleChange} value={this.state.title} />
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="description">Description:</label>
				      <div className="col-md-10">          
				        <textarea required type="text" className="form-control" id="description" name="description" onChange={this.handleChange} value={this.state.description}>
				        </textarea>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="amt">Reward Price:</label>
				      <div className="col-md-10">          
				        <input required type="number" className="form-control" id="amt" name="fixed_amount" onChange={this.handleChange} value={this.state.fixed_amount}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="form_title">Form Title:</label>
				      <div className="col-md-10">          
				        <input required type="text" className="form-control" id="form_title" name="form_title" onChange={this.handleChange} value={this.state.form_title}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="form_intro">Form Intro:</label>
				      <div className="col-md-10">          
				        <input required type="text" className="form-control" id="form_intro" name="form_intro" onChange={this.handleChange} value={this.state.form_intro}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="form_details">Form Details:</label>
				      <div className="col-md-10">          
				        <textarea required type="text" className="form-control" id="form_details" name="form_details" onChange={this.handleChange} value={this.state.form_details}>
				        </textarea>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="stage">Stage:</label>
				      <div className="col-md-10">          
				        <input disabled type="number" className="form-control" id="stage" name="stage" onChange={this.handleChange} value={this.state.stage}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="checkbox-inline control-label col-md-12" htmlFor="approval_required">     
				        <input type="checkbox" id="approval_required" name="approval_required" onChange={this.handleCheck} value={this.state.approval_required} defaultChecked={this.state.approval_required}/>
				        <b>Approval Required</b>
				      </label> 
				    </div>
				    <div className="form-group">        
				      <div className="col-md-12">
				        <button type="submit" className="btn btn-default" style={{"float":"right"}}>Create Task</button>
				      </div>
				    </div>
			  </form>
			</div>
		);	
	}
}

export default TaskForm;