import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import JobCard from './job_card';
import JobForm from './job_form';

class ViewJobs extends Component{
	constructor(props) {
	    super(props);

	    this.state = {
			jobs: [],
			logged_in: false,
			username: '',
			show_form: false,
			show_pending: false,
			show_active: false,
		}

		if(typeof ReactSession.get("username") !== 'undefined'){
			this.state.logged_in = true;
			this.state.username = ReactSession.get("username");
		}
		this.fetch_jobs = this.fetch_jobs.bind(this);
		this.generate_job_list = this.generate_job_list.bind(this);
		this.hide_or_show_form = this.hide_or_show_form.bind(this);
		this.hide_or_show_pending = this.hide_or_show_pending.bind(this);
		this.hide_or_show_active = this.hide_or_show_active.bind(this);
		this.hide_or_show_all = this.hide_or_show_all.bind(this);
		this.count_jobs = this.count_jobs.bind(this);
	}

	componentDidMount(){
		if(this.state.logged_in)
			this.fetch_jobs();
	}

	fetch_jobs = () => {
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var data = [];
		axios.get(global.config.apiGateway.URL+"/admin/jobs",
			{ headers: { Authorization: access_token } })
		.then((response) => {
			data = response.data;
			this.setState({jobs:data});
		})
		.catch((err) => {
			console.error(String(err));
		});
	}

	generate_job_list = () => {
		var jobs_list = ""
		if(this.state.jobs.length){
			jobs_list = this.state.jobs.map((job) =>{
				if(this.state.show_active){
					if(job.is_active){
						return(
							<JobCard job={job} key={job.id}/>
						);
					}
					else{
						return null;
					}
				}
				else if(this.state.show_pending){
					if(!job.approved_by&&!job.deleted_by&&!job.is_active){
						return(
							<JobCard job={job} key={job.id}/>
						);
					}
					else{
						return null;
					}
				}
				else{
					return(
							<JobCard job={job} key={job.id}/>
						);
				}
			});
		}
		else{
			jobs_list = <div>
							<h3>No jobs to show</h3>
						</div>;
		}
		return jobs_list;
	}

	count_jobs = () => {
		var active = 0;
		var all = 0;
		var pending = 0;
		this.state.jobs.map((job) =>{
			if(job.is_active){
				active++;
			}
			if(!job.approved_by&&!job.deleted_by&&!job.is_active){
				pending++;
			}
			all++;
			return null;
		});
		return {
			all: all,
			active: active,
			pending: pending
		};
	}
	
	hide_or_show_form = () => {
		this.setState({show_form: !this.state.show_form});
	}

	hide_or_show_pending = () => {
		this.setState({show_pending: !this.state.show_pending, show_active: false});
	}

	hide_or_show_active = () => {
		this.setState({show_active: !this.state.show_active, show_pending: false});
	}

	hide_or_show_all = () => {
		this.setState({
			show_pending: false,
			show_active: false
		});
	}
	render(){
		if(!this.state.logged_in){
			return(
				<div>
					<h5>Not logged in !</h5>
				</div>
			);
		}
		var jobs_list = this.generate_job_list();
		const {all, pending, active} = this.count_jobs();
		return(
			<div className="row">
				<div className="col-md-12 jumbotron">
					<h2 style={{"display":"inline-block"}}>Jobs</h2>
					<button className="btn btn-default" onClick={this.hide_or_show_form} style={{float: "right", "margin-top": "20px"}}>Create New Job</button>
				</div>
				{	this.state.show_form && <JobForm/> }
				<div className="col-md-12" style={{"margin-bottom":"30px"}}>
					<div className="col-md-4">
						<h5 style={{"display":"inline-block"}}>Total Jobs : {all}</h5>
						<button className="btn btn-xs" onClick={this.hide_or_show_all} style={{"margin-left": "10px"}}>Show</button>
					</div>
					<div className="col-md-4">
						<h5 style={{"display":"inline-block"}}>Jobs Pending for Approval : {pending}</h5>
						<button className="btn btn-xs" onClick={this.hide_or_show_pending} style={{"margin-left": "10px"}}>Show</button>

					</div>
					<div className="col-md-4">
						<h5 style={{"display":"inline-block"}}>Active Jobs : {active}</h5>
						<button className="btn btn-xs" onClick={this.hide_or_show_active} style={{"margin-left": "10px"}}>Show</button>
					</div>
				</div>
				{jobs_list}
			</div>
		);
	}
}

export default ViewJobs;
