import React,{Component} from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Scheme from './scheme';


class SchemeList extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	task_id: props.task_id,
	    	found: false,
	    	logged_in: false,
	    	schemes: [],
		};

		if(typeof ReactSession.get("username") !== 'undefined' && typeof ReactSession.get("access_token") !== 'undefined'){
			this.state.logged_in= true;
			this.state.username = ReactSession.get("username");
		}
		this.fetch_schemes = this.fetch_schemes.bind(this);
		this.generate_schemes_list = this.generate_schemes_list.bind(this);
	}

	componentDidMount(){
		if(this.state.logged_in)
			this.fetch_schemes();
	}

	componentDidUpdate(prevProps) {
	    if(prevProps!==this.props&&this.state.logged_in)
			this.fetch_schemes();
	} 
	fetch_schemes = () => {
		var access_token = 'Bearer ' + ReactSession.get("access_token");

		axios.get(global.config.apiGateway.URL+"/admin/tasks/"+this.state.task_id+"/schemes",
			{ 
				headers: { 
					Authorization: access_token 
				}
			})
		.then((response) => {
			if(response.status === 200){
				this.setState({
					schemes: response.data,
					found: true
				});
			}
		})
		.catch((err)=>{
			console.error(String(err));
		});
	}

	generate_schemes_list = () => {
		var data;
		if(this.state.schemes.length){
			data = this.state.schemes.map((scheme) => {
					return (
						<Scheme key={scheme.id} scheme={scheme} reload={this.fetch_schemes}/>
					);
				});
		}
		else{
			data = <div className="col-md-12">
							<h4 style={{"color": "red"}}>No schemes found!</h4>
						 </div>
		}
		return data;
	}

	render(){
		var data = this.generate_schemes_list();
		return (
			<div className="list-group">
				{data}
			</div>
		);
	}
}

export default SchemeList;