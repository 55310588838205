import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import SchemeForm from '../scheme/scheme_form';
import SchemeList from '../scheme/scheme_list';
import FormFieldList from './form_field_list';
import FormFieldForm from './form_field_form';

class Task extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	task_id: props.match.params.task_id,
	    	found: false,
	    	logged_in: false,
	    	show_scheme_form: false,
	    	show_form_field_form: false
		};

		if(typeof ReactSession.get("username") !== 'undefined' && typeof ReactSession.get("access_token") !== 'undefined'){
			this.state.logged_in= true;
			this.state.username = ReactSession.get("username");
		}
		this.fetch_task = this.fetch_task.bind(this);
		this.generate_task_data = this.generate_task_data.bind(this);
		this.approve_task = this.approve_task.bind(this);
		this.delete_task = this.delete_task.bind(this);
		this.count_form_pos = this.count_form_pos.bind(this);
		this.assign_amount_form_field = this.assign_amount_form_field.bind(this);
	}
	componentDidMount(){
		if(this.state.logged_in)
			this.fetch_task();
	}

	fetch_task = () => {
		var task_id = this.state.task_id;
		var access_token = 'Bearer ' + ReactSession.get("access_token");

		axios.get(global.config.apiGateway.URL+"/admin/tasks/"+this.state.task_id,
			{ 
				headers: { 
					Authorization: access_token 
				},
				params: {
					task_id: task_id
				} 
			})
		.then((response) => {
			if(response.status === 200){
				this.setState({
					task_data: response.data,
					found: true
				});
			}
		})
		.catch((err)=>{
			console.error(String(err));
		});
	}

	generate_task_data = () => {
		var task_data;
		task_data =	<div className="col-md-10 row">
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h3>Task Title :</h3> 
							</div>
							<div className="col-md-8">
								<h3>{this.state.task_data.title}</h3>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Task Stage :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.task_data.stage}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Fixed Amount :</h4> 
							</div>
							<div className="col-md-8">
								<h4>₹ {this.state.task_data.fixed_amount}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Task Description :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.task_data.description}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Task ID :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.task_data.id}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Created By User :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.task_data.created_by}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Approval Required :</h4> 
							</div>
							<div className="col-md-8">
								<h4>
									{this.state.task_data.approval_required
										? "Yes"
										: "No"
									}
								</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Approved By User :</h4> 
							</div>
							<div className="col-md-8">
								<h4>
									{this.state.task_data.approved_by
										? this.state.task_data.approved_by
										: <p style={{color:"red"}}>Not Approved</p>
									}
								</h4>
							</div>
						</div>
						{this.state.task_data.deleted_by&&
							<div className="col-md-12 row">
								<div className="col-md-4">
									<h4>Deleted By User :</h4> 
								</div>
								<div className="col-md-8">
									<h4>
										this.state.task_data.deleted_by
									</h4>
								</div>
							</div>
						}
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Active or not :</h4> 
							</div>
							<div className="col-md-8">
								{
								 this.state.task_data.is_active 
								 ? <h4 style={{"color":"green"}}>Active✔️</h4>
								 : <h4>Not Active⌛</h4>
								}
							</div>
						</div>
					</div>
		return task_data;
	}

	approve_task = () => {
		if(!window.confirm("Approve this Task?"))
			return;
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('task_id', this.state.task_id);
		axios({
		  method: "put",
		  url: global.config.apiGateway.URL+"/admin/tasks/"+this.state.task_id+"/approval",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(String(response));
			if(response.status === 200){
				alert('Task Approved');
				this.fetch_task();
			}
		})
		.catch((err) => {
			console.log(String(err));
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Task not found');
			}
		});
	}

	delete_task = () => {
		if(!window.confirm("Delete this Task?"))
			return;
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('task_id', this.state.task_id);
		axios({
		  method: "delete",
		  url: global.config.apiGateway.URL+"/admin/tasks/"+this.state.task_id,
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(String(response));
			if(response.status === 200){
				alert('Task Deleted');
				this.fetch_task();
			}
		})
		.catch((err) => {
			console.log(String(err));
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Task not found');
			}
		});
	}

	count_form_pos = () => {
		var pos = 0;
		this.state.task_data.form_fields.map((field)=>{
			if(field.position>pos){
				pos = field.position;
			}
			return null;
		});
		return pos;
	}

	hide_or_show_scheme_form = () => {
		if(this.state.show_scheme_form)
			this.setState({show_scheme_form: false});
		else
			this.setState({show_scheme_form: true});
	}

	hide_or_show_form_field_form = () => {
		if(this.state.show_form_field_form)
			this.setState({show_form_field_form: false});
		else
			this.setState({show_form_field_form: true});
	}

	assign_amount_form_field = (form_field_id) => {
		if(!window.confirm("Assign this Form Field?"))
			return;
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('form_field_id', form_field_id);
		axios({
		  method: "put",
		  url: global.config.apiGateway.URL+"/admin/tasks/"+this.state.task_id+"/add_amount_form_field",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(String(response));
			if(response.status === 200){
				alert('Form Field Assigned!');
				this.fetch_task();
			}
		})
		.catch((err) => {
			console.log(String(err));
			if(err.response.status === 400){
				alert(err.response.data);
			}
		});
	}

	render(){
		if(!this.state.logged_in){
			return(
				<div>
					<h5>Not logged in !</h5>
				</div>
			);
		}
		else if(!this.state.found){
			return(
				<div>
					<h5>Task {this.state.task_id} not found !</h5>
				</div>
			);
		}
		else{
			var task_data = this.generate_task_data();
			return(
				<div className="row">
					<div className="col-md-12 page-header text-center">
						<h1><small><Link to={"/job/"+this.state.task_data.job_id}>Job ID - {this.state.task_data.job_id}</Link></small> Task - {this.state.task_data.title} </h1>
					</div>
					<div className="col-md-12 row">
						{task_data}
						<div className="col-md-2 row">
							<div className="col-md-12" style={{"margin":"5px"}}>
								<Link to={"/task/"+this.state.task_id+"/task_instances"}>View Task Instances</Link>
							</div>
							<div className="col-md-12" style={{"margin":"5px"}}>
								{this.state.task_data.deleted_by
										? <p style={{"color":"red"}}>Task Deleted❌</p>
										: this.state.task_data.is_active
											? <p style={{"color":"green"}}>Task Approved✔️</p>
											:this.state.task_data.can_be_approved
											  ? <button className="btn" onClick={this.approve_task}>Approve Task</button>
											  : <p style={{color: "red"}}>Add form field to approve!</p>
								}
							</div>
							<div className="col-md-12" style={{"margin":"5px"}}>
								{!this.state.task_data.deleted_by&&
									<button className="btn btn-danger" onClick={this.delete_task}>Delete Task</button>
								}
							</div>
						</div>
					</div>
					<div className="col-md-12 row page-header">
						<div className="col-md-8">
							<h4>Questions</h4>
						</div>
						{!this.state.task_data.deleted_by&&
							<div className="col-md-4">
								<button className="btn" onClick={this.hide_or_show_form_field_form} style={{"margin-top": "10px"}}>Add a Question</button>
							</div>
						}
					</div>
					{ this.state.show_form_field_form && 
						<FormFieldForm task_id={this.state.task_id} form_pos={this.count_form_pos()+1} hide={this.hide_or_show_form_field_form} reload={this.fetch_task}/> 
					}
					<FormFieldList assign={this.assign_amount_form_field} amount_form_field={this.state.task_data.amount_form_field} form_fields={this.state.task_data.form_fields} reload={this.fetch_task}/>

					<div className="col-md-12 row page-header">
						<div className="col-md-8">
							<h3>Schemes</h3>
						</div>
						{!this.state.task_data.deleted_by&&
							<div className="col-md-4">
								<button className="btn" onClick={this.hide_or_show_scheme_form} style={{"margin-top": "10px"}}>Add Scheme</button>
							</div>
						}
					</div>
					{ this.state.show_scheme_form && 
						<SchemeForm task_id={this.state.task_id} hide={this.hide_or_show_scheme_form} reload={this.fetch_task}/> 
					}
					<SchemeList task_id={this.state.task_id} reload={this.fetch_task}/>
				</div>

			);
		}
	}

}

export default Task;