import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";
import DownloadWorkbook from "./downloadWorkbook";

class PaymentSheet extends Component {
  constructor() {
    super();
    this.state = {
      start_datetime: "",
      end_datetime: "",
      gp_responses: [],
      expanded_responses: [],
      show_csv: false,
      loading: false,
      logged_in: false,
    };

    if (
      typeof ReactSession.get("username") !== "undefined" &&
      typeof ReactSession.get("access_token") !== "undefined"
    ) {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    console.log(e.target.value);

    this.setState({
      [nam]: val,
      show_csv: false,
      gp_responses: [],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    //check validity of datetime inputs
    if (document.getElementById("start_datetime").validity.valid === false) {
      alert("Please select a starting Date");
      return;
    }
    if (document.getElementById("end_datetime").validity.valid === false) {
      alert("Please select an End Date");
      return;
    }

    this.setState({
      loading: true,
    });

    // convert timestamp into utc for sending to backend
    var momentTz = require("moment-timezone");
    // db stores timestamp as +5:30 hours, so we need to add 5 hours 30 minutes for input time stamp to be comparable to actual time
    var start_dt = momentTz
      .tz(this.state.start_datetime, "YYYY-MM-DD HH:mm", "Asia/Kolkata")
      .add(5, "hours")
      .add(30, "minutes");
    start_dt = start_dt.format("YYYY-MM-DD HH:mm");
    var end_dt = momentTz
      .tz(this.state.end_datetime, "YYYY-MM-DD HH:mm", "Asia/Kolkata")
      .add(5, "hours")
      .add(30, "minutes");
    end_dt = end_dt.format("YYYY-MM-DD HH:mm");

    var access_token = "Bearer " + ReactSession.get("access_token");
    var params;
    params = {
      start_date: start_dt,
      end_date: end_dt,
    };
    let sheet1URL = global.config.apiGateway.URL + "/admin/users/payment-sheet";
    let sheet2URL =
      global.config.apiGateway.URL +
      "/admin/users/payment-sheet/transaction-task-breakdown";
    const sheet1Promise = axios.get(sheet1URL, {
      headers: { Authorization: access_token },
      params: params,
    });
    const sheet2Promise = axios.get(sheet2URL, {
      headers: { Authorization: access_token },
      params: params,
    });
    axios
      .all([sheet1Promise, sheet2Promise])
      .then((response) => {
        if (response[0].status === 200 && response[1].status === 200) {
          // console.log(response);

          this.setState({
            gp_responses: response[0].data,
            expanded_responses: response[1].data,
            loading: false,
            show_csv: true,
          });
          if (response[0].data.length === 0) {
            alert(
              "Looks like we haven't found any transaction for given date range."
            );
          }
          console.log("Fetch data success");
        } else if (response[0].status === 204) {
          alert("No payment details for this date range");
        } else {
          this.setState({
            loading: false,
          });
          alert(
            "unable to fetch data, please ensure correct date and internet connection."
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({
          loading: false,
        });
        alert(String(err));
      });
    // axios
    //   .get(global.config.apiGateway.URL + "/admin/users/payment-sheet", {
    //     headers: { Authorization: access_token },
    //     params: params,
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     if (response.status === 200) {
    //       data = response.data;
    //       console.log(data);
    //       this.setState({
    //         gp_responses: data,
    //         show_csv: loadedSheet2,
    //         loading: false,
    //         loadedSheet1: true
    //       });
    //       alert("Payment Details Fetched");
    //     } else if (response.status === 204) {
    //       this.setState({
    //         loading: false,
    //       });
    //       alert("No Payment details for this date");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //     this.setState({
    //       loading: false,
    //     });
    //     if (err.response.status === 400) {
    //       alert(err.response.data);
    //     }
    //     if (err.response.status === 404) {
    //       console.log("Task not found");
    //     }
    //   });
  };

  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 style={{ margin: "40px", textAlign: "center" }}>
                Generate Payment Sheet
              </h3>
            </div>
          </div>
          <form className="form-horizontal row" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label
                className="control-label col-md-3"
                htmlFor="start_datetime"
                style={{ textAlign: "left" }}
              >
                Start DateTime:
              </label>
              <div className="col-md-9">
                <input
                  type="datetime-local"
                  className="form-control"
                  id="start_datetime"
                  name="start_datetime"
                  min="2020-01-01T00:00"
                  max="2030-01-01T00:00"
                  onChange={this.handleChange}
                  value={this.state.start_datetime}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label
                className="control-label col-md-3"
                htmlFor="end_datetime"
                style={{ textAlign: "left" }}
              >
                End DateTime:
              </label>
              <div className="col-md-9">
                <input
                  type="datetime-local"
                  className="form-control"
                  id="end_datetime"
                  name="end_datetime"
                  min="2020-01-01T00:00"
                  max="2030-01-01T00:00"
                  onChange={this.handleChange}
                  value={this.state.end_datetime}
                  required
                />
              </div>
            </div>
          </form>
          <div className="form-horizontal row">
            <div className="form-group">
              <div className="col-md-offset-2 col-md-10">
                {!this.state.show_csv ? (
                  <button
                    className="btn btn-default"
                    style={{ float: "right" }}
                    onClick={this.handleSubmit}
                    disabled={this.state.loading}
                  >
                    {this.state.loading
                      ? "Fetching Responses"
                      : "Fetch Responses"}
                  </button>
                ) : (
                  <div className="download" style={{ float: "right" }}>
                    {/* <CsvDownload
                      data={this.state.gp_responses}
                      filename={"paymentSheet.csv"}
                      className="btn btn-default"
                    >
                      Download CSV
                    </CsvDownload> */}
                    <DownloadWorkbook
                      desc="Custom component that allows user to download a workbook on button click."
                      sheet1JSON={this.state.gp_responses}
                      sheet2JSON={this.state.expanded_responses}
                      filename={
                        "paymentSheet" +
                        this.state.start_datetime +
                        " " +
                        this.state.end_datetime +
                        ".xlsx"
                      }
                      className="btn btn-default"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PaymentSheet;
