import React,{Component} from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import SchemeSegForm from './scheme_segment_form';


class Scheme extends Component{
	constructor(props) {
	    super(props);
		this.generate_segments_list = this.generate_segments_list.bind(this);
		this.delete_segment = this.delete_segment.bind(this);
		this.delete_scheme = this.delete_scheme.bind(this);
	}

	generate_segments_list = () => {
		var segments = this.props.scheme.segments.map((seg)=>{
			return (
					<div className="col-md-12 row" style={{"margin":"5px"}} key={seg.id}>
						<div className="col-md-2">
							<b>Constraint ID {seg.id}.</b> 
						</div>
						<div className="col-md-2">
							Constraint Over : {seg.type}
						</div>
						<div className="col-md-3">
							Constraint : {seg.segment_name}.
						</div>
						<div className="col-md-2">
					        {seg.is_active
								? <p style={{"color":"green"}}>Active✔️</p>
								: <p style={{"color":"red"}}>Deleted❌</p>
							 }
						</div>
						{seg.is_active&&
							<div className="col-md-3">
								<button className="btn btn-danger btn-sm" id={seg.id} onClick={this.delete_segment}>Delete</button>
							</div>
						}
					</div>
				);
		});
		return segments;
	}

	delete_segment = (e) => {
		if(!window.confirm("Delete this Scheme Segment?"))
			return;
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('scheme_seg_map_id', e.target.id);
		axios({
		  method: "delete",
		  url: global.config.apiGateway.URL+"/admin/schemes/segments/"+e.target.id,
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(String(response));
			if(response.status === 200){
				alert('Scheme Segment Deleted');
				this.props.reload();
			}
		})
		.catch((err) => {
			console.log(String(err));
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Scheme not found');
			}
		});
	}

	delete_scheme = (e) => {
		if(!window.confirm("Delete this Scheme?"))
			return;
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		axios({
		  method: "delete",
		  url: global.config.apiGateway.URL+"/admin/tasks/schemes/"+this.props.scheme.id,
		  headers: {
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(String(response));
			if(response.status === 200){
				alert('Scheme Deleted');
				this.props.reload();
			}
		})
		.catch((err) => {
			console.log(String(err));
			if(err.response.status === 400){
				alert(err.response.data);
			}
		});
	}

	render(){
		var scheme = this.props.scheme;
		var segments = this.generate_segments_list();
		return (
			<div className="list-group">
				<div className="col-md-12 row list-group-item" key={scheme.id}>
						<div className="row">
							<div className="col-md-3">
								<b>ID : {scheme.id}.</b>
							</div>
							<div className="col-md-3">
								Created By : {scheme.created_by_name}
							</div>
							<div className="col-md-3">
								Type : {scheme.type}
							</div>
							<div className="col-md-3">
								Incentive : ₹ {scheme.incentive}
							</div>
						</div>
						<div className="row" style={{"margin-top":"10px"}}>
							<div className="col-md-6">
								Text : {scheme.text}
							</div>
							
							<div className="col-md-3">
								Milestone : {scheme.milestone}
							</div>
							<div className="col-md-3">
								Milestone Unit : {scheme.milestone_unit}
							</div>
						</div>
						<div className="row" style={{"margin-top":"10px", "padding-bottom":"10px", "border-bottom":"1px dashed #ddd"}}>
							<div className="col-md-2">
								Pay Frequency : {scheme.pay_frequency}
							</div>
							
							<div className="col-md-4">
								Action Text : {scheme.action_text}
							</div>
							<div className="col-md-2">
								{scheme.is_active
									?<b><p style={{"color":"green"}}>Active✔️</p></b>
									:<b><p style={{"color":"red"}}>Deleted❌</p></b>
								}
							</div>
							{scheme.is_active&&
								<div className="col-md-4">
									<button className="btn btn-danger btn-sm" onClick={this.delete_scheme}>Delete Scheme</button>
								</div>
							}
						</div>
						<div className="row" style={{"margin-top":"10px"}}>
							<div className="col-md-12">
								<SchemeSegForm scheme_id={scheme.id} reload={this.props.reload}/>
							</div>
						</div>
						{segments}
					</div>
			</div>
		);
	}
}

export default Scheme;