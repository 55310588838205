import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';


class SchemeForm extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	text: '',
	    	type: "count",
	    	milestone: '',
	    	incentive: '',
	    	pay_frequency: 7,
	    	milestone_unit: '',
	    	action_text: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		console.log(this.state);
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('task_id', this.props.task_id);
		formdata.append('text', this.state.text);
		formdata.append('type', this.state.type);
		formdata.append('milestone', this.state.milestone);
		formdata.append('incentive', this.state.incentive);
		formdata.append('pay_frequency', this.state.pay_frequency);
		formdata.append('milestone_unit', this.state.milestone_unit);
		formdata.append('action_text', this.state.action_text);
		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/admin/tasks/"+this.props.task_id+"/schemes",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(response);
			if(response.status === 200){
				alert('Scheme Created');
				this.props.hide();
				this.props.reload();
				// window.location.reload(false);
			}
		})
		.catch((err) => {
			console.error(err.response);
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Task not found');
			}
		});
	}


	render(){

		return(
			<div className="col-md-12">
				<div className="col-md-3 row">
					<div className="col-md-12">
						<h3>Create a Scheme</h3>
					</div>
				</div>
				<form className="form-horizontal col-md-9" onSubmit={this.handleSubmit}>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="txt">Scheme Text:</label>
				      <div className="col-md-10">          
				        <textarea required type="text" className="form-control" id="txt" name="text" onChange={this.handleChange} value={this.state.text}>
				        </textarea>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="action_text">Action Text:</label>
				      <div className="col-md-10">          
				        <input required type="text" className="form-control" id="action_text" name="action_text" onChange={this.handleChange} value={this.state.action_text}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="type">Scheme Type:</label>
				      <div className="col-md-10">
					      <select required type="text" className="form-control" id="type" name="type" onChange={this.handleChange} value={this.state.type}>
					        <option>count</option>
					        <option>amount</option>
					        <option>offline</option>
					      </select>
					   </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="milestone">Target:</label>
				      <div className="col-md-10">          
				        <input type="number" className="form-control" id="milestone" name="milestone" onChange={this.handleChange} value={this.state.milestone}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="incentive">Incentive:</label>
				      <div className="col-md-10">          
				        <input type="number" className="form-control" id="incentive" name="incentive" onChange={this.handleChange} value={this.state.incentive}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="pay_frequency">Pay Frequency:</label>
				      <div className="col-md-10">          
				        <select required type="number" className="form-control" id="pay_frequency" name="pay_frequency" onChange={this.handleChange} value={this.state.pay_frequency}>
					        <option>7</option>
					        <option>15</option>
					        <option>30</option>
					        <option>90</option>
					      </select>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-2" htmlFor="milestone_unit">Target Unit:</label>
				      <div className="col-md-10">          
				        <input type="text" className="form-control" id="milestone_unit" name="milestone_unit" onChange={this.handleChange} value={this.state.milestone_unit}/>
				      </div>
				    </div>
				    <div className="form-group">        
				      <div className="col-md-offset-2 col-md-10">
				        <button type="submit" className="btn btn-default" style={{float:"right"}}>Add Scheme</button>
				      </div>
				    </div>
			  </form>
			</div>
		);	
	}
}

export default SchemeForm;