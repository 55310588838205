import React,{Component} from 'react';
import { Redirect } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';

class JobForm extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	job_id: false,
	    	redirect: false,
	    	title: '',
	    	description: '',
			instruction_file_url: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		var title = this.state.title.trim();
		var description = this.state.description.trim();
		var instruction_file_url = this.state.instruction_file_url.trim();
		this.setState({
			title : title,
			description: description,
			instruction_file_url: instruction_file_url,
		});
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('title', title);
		formdata.append('description', description);
		if (instruction_file_url.length!==0)
			formdata.append('instruction_file_url', instruction_file_url)
		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/admin/jobs",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(response);
			if(response.status === 200){
				alert('Job Created');
				this.setState({redirect: '/job/'+response.data.id, job_id: response.data.id});
			}
		})
		.catch((err) => {
			console.error(String(err));
			if(err.response.status === 400){
				alert(err.response.data);
			}
		});
	}

	render(){
		if (this.state.redirect) {
		    return <Redirect to={this.state.redirect} />
		  }
		return(
			<div className="col-md-12" style={{"margin-bottom":"30px"}}>
				<div className="col-md-4 row">
					<div className="col-md-12">
						<h3>Create a Job</h3>
					</div>
				</div>
				<form className="form-horizontal col-md-8" onSubmit={this.handleSubmit}>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="title">Title:</label>
				      <div className="col-md-9">
				        <input required type="text" className="form-control" id="title" placeholder="Enter title of job" name="title" onChange={this.handleChange} value={this.state.title}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="description">Description:</label>
				      <div className="col-md-9">          
				        <textarea  required type="text" className="form-control" id="description" placeholder="Enter description of job" name="description" onChange={this.handleChange} value={this.state.description}>
				        </textarea>
				      </div>
				    </div>
					<div className="form-group">
				      <label className="control-label col-md-3" htmlFor="instruction_file_url">Instruction File:</label>
				      <div className="col-md-9">          
				        <textarea type="text" className="form-control" id="instruction_file_url" placeholder="Enter URL of the Instruction File" name="instruction_file_url" onChange={this.handleChange} value={this.state.instruction_file_url} style={{"color":"blue"}}>
				        </textarea>
				      </div>
				    </div>
				    <div className="form-group">        
				      <div className="col-md-offset-2 col-md-10">
				        <button type="submit" className="btn btn-default" style={{float:"right"}}>Submit</button>
				      </div>
				    </div>
			  </form>
			</div>
		);	
	}
}

export default JobForm;