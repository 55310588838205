import React, { useState } from "react";
import axios from "axios";
import { ReactSession } from "react-client-session";
import Select, { ActionMeta } from "react-select";
// import "./test2.css";

// const deletedArray = [];

const SchemeRow = ({
  check,
  id,
  districts,
  states,
  startDate,
  endDate,
  handleDistrictRank,  
  handleStateRank,
  handleNationalRank,
  setAddSchemeActiveClose,
  // schemeEdit,
  // setSchemeEdit,
  //   handleEdit,
}) => {
  const [schemeEdit, setSchemeEdit] = useState(false);
  const [addSchemeActive, setAddSchemeActive] = useState(false);
  const [items, setItems] = useState({});
  const [rows, setRows] = useState([{}]);
  const [postData, setPostData] = useState({
    leaderboard_scheme_id: "",
    rank_in: "",
    start_date: "",
    segment_ids: [],
    end_date: "",
    modified_ranks: [],
    deleted_segment_ids: [],
    new_segment_ids: [],
    prizes: [],
  });

  const [districtIds, setDistrictIds] = useState([]);
  const [schemRowsContainer, setSchemRowsContainer] = useState([]);
  const [uniqueIds, setUniqueIds] = useState([]);
  const [modifiedRankRows, setModifiedRankRows] = useState([]);
  const [deleteScheme, setDeleteScheme] = useState(false);

  const columnsArray = ["Daily", "Weekly", "Monthly"];
  const handleAddRow = () => {
    const item = {};
    setRows([...rows, item]);
    setRows((state) => {
      console.log("rowsIndsideHandleAddRows", state);
      return state;
    });
    // console.log("rowsIndsideHandleAddRows", rows);
  };

  //   const postResults = () => {
  //     console.log(rows);
  //   };

  const handleRemoveRow = () => {
    const tempRows = [...rows];
    // console.log("deletedRanks", tempRows.pop());
    const deletedRowData = tempRows[tempRows.length - 1];
    console.log("deletedRowData", deletedRowData.rank_id);
    setPostData({
      ...postData,
      modified_ranks: [
        ...postData.modified_ranks,
        deletedRowData.rank_id,
      ].filter(function (element) {
        return element !== undefined;
      }),
    });
    console.log("modified ranks", postData.modified_ranks);
    setRows((currentRow) => [...currentRow.slice(0, -1)]);
  };

  let mainIndex = schemRowsContainer.length + 1;

  const updateState = (e) => {
    let prope = e.target.attributes.column.value;
    let index = e.target.attributes.index.value;
    let fieldValue = e.target.value;

    const tempRows = [...rows];
    const tempObj = rows[index];
    tempObj[prope] = fieldValue;

    tempRows[index] = tempObj;
    console.log("tempRowsInsideUpdateState", tempRows);
    setRows(tempRows);

    let difference = rows.filter((x) => !schemRowsContainer.includes(x));
    console.log("difference of two arrays", difference);

    const mainRows = difference.map((item, idx) => {
      return {
        daily_reward: item.Daily,
        monthly_reward: item.Monthly,
        position: mainIndex++,
        weekly_reward: item.Weekly,
      };
    });

    console.log("schemeRowsContainerForModifiedRanks", schemRowsContainer);
    console.log("RowsContainerForModifiedRanks", rows);

    const tempArray = [];
    const tempArrayWithDetails = [];
    for (let i = 0; i < modifiedRankRows.length; i++) {
      if (
        modifiedRankRows[i].daily_reward !== rows[i].Daily ||
        modifiedRankRows[i].weekly_reward !== rows[i].Weekly ||
        modifiedRankRows[i].monthly_reward !== rows[i].Monthly
      ) {
        // console.log(
        //   "schemRowsContainer[i].rank_id",
        //   modifiedRankRows[i].rank_id
        // );
        console.log("modifiedRankRows[i].position", rows[i].position);
        const nextArray = {
          daily_reward: rows[i].Daily,
          monthly_reward: rows[i].Monthly,
          position: modifiedRankRows[i].position,
          weekly_reward: rows[i].Weekly,
        };
        tempArray.push(modifiedRankRows[i].rank_id);
        tempArrayWithDetails.push(nextArray);
      }
    }
    console.log("tempArrayforModifiedRanks", tempArray);
    const uniqueModifiedRanks = [...new Set(tempArray)];
    console.log("uniqueModifiedRanks", uniqueModifiedRanks);
    const uniqueModifiedRanksWithDetails = [...new Set(tempArrayWithDetails)];
    console.log(
      "uniqueModifiedRanksWithDetails",
      uniqueModifiedRanksWithDetails
    );

    // setPostData({
    //   ...postData,
    // });

    // setPostData((state) => {
    //   console.log("state.modified_ranks", state.modified_ranks);
    // });

    console.log("UpdatedschemesRows", mainRows);
    setPostData({
      ...postData,
      modified_ranks: [
        ...new Set([...postData.modified_ranks, ...uniqueModifiedRanks]),
      ],
      prizes: [...mainRows, ...uniqueModifiedRanksWithDetails],
    });
    // console.log("updatedRankRows", rows);
  };

  var access_token = "Bearer " + ReactSession.get("access_token");

  const handleEdit = (id) => {
    setAddSchemeActiveClose(false);
    setSchemeEdit(!schemeEdit);
    // setSchemeEdit(true);
    console.log(id);
    const getData = async () => {
      const res = await fetch(
        global.config.apiGateway.URL + `/admin/leaderboard/schemes/${id}`,
        {
          headers: {
            Authorization: access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      setItems({ ...items, ...data });
      setItems((state) => {
        console.log(state);
        setModifiedRankRows(state && state.prizes);
        const mainArray =
          state &&
          state.prizes.map((item, idx) => {
            return {
              rank_id: item.rank_id,
              position: item.position,
              Daily: item.daily_reward,
              Weekly: item.weekly_reward,
              Monthly: item.monthly_reward,
            };
          });
        mainArray.sort(function (a, b) {
          return a.position - b.position;
        });
        setRows(mainArray);
        setSchemRowsContainer(mainArray);
        console.log("mainArray", mainArray);
        setPostData({
          ...postData,
          rank_in: state.rank_in,
          leaderboard_scheme_id: id,
          start_date: dateConverter(state.start_date),
          end_date: dateConverter(state.end_date),
        });

        const fakeOrignalSegmentIds = state.seg_mapping.map((item) => {
          return {
            orignalId: item.segment_id,
            fakeId: item.id,
          };
        });
        setDistrictIds(fakeOrignalSegmentIds);
        setUniqueIds(
          fakeOrignalSegmentIds.map((item) => {
            return item.orignalId;
          })
        );
        return state;
      });
      console.log("items", items);
    };
    getData();
  };

  const addNewSchemeHandler = (e) => {
    e.preventDefault();
  };

  let monthNames = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateConverter = (date) => {
    console.log("date", date);
    // String(date);
    if (date.length > 10) {
      let dt = date.slice(5, 7);
      let yr = date.slice(12, 16);
      let mon = date.slice(8, 11);
      let ind = monthNames.indexOf(mon);
      if (ind <= 9) {
        ind = "0" + ind;
      }
      return yr + "-" + ind + "-" + dt;
    } else {
      return date;
    }
  };

  const locationDataConverter = (data) => {
    console.log(data);
    console.log("itemInsideLocationDataConverter", data);

    const arr =
      data &&
      data.map((item, idx) => {
        return {
          value: item.segment_id ? item.segment_id : item.value,
          label: item.segment_name ? item.segment_name : item.label,
          fake_id: item.id && item.id,
        };
      });
    return arr;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const d1 = new Date(postData.start_date);
    const d2 = new Date(postData.end_date);
    if (d1 > d2) {
      alert("Start date cannot be greater than end date!");
      return;
    }
    console.log("post data after edit", postData);
    axios({
      method: "put",
      url: global.config.apiGateway.URL + "/admin/leaderboard/schemes",
      data: {
        ...postData,
        rank_in: check === "district" ? 1 : check === "state" ? 2 : 3,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          alert("Scheme Edited!");
        }
        if (check === "district") {
          handleDistrictRank();
        } else if (check === "state") {
          handleStateRank();
        } else if (check === "national") {
          handleNationalRank();
        }
        setSchemeEdit(false);
      })
      .catch((error) => {
        console.error(String(error));
      });
  };

  const handleSchemeDelete = (id) => {
    console.log("handleSchemeDelete", id);
    if (!window.confirm("Delete this Scheme?")) return;
    var access_token = "Bearer " + ReactSession.get("access_token");
    // var formdata = new FormData();
    // formdata.append("leaderboard_scheme_id", id);
    axios({
      method: "delete",
      url: global.config.apiGateway.URL + "/admin/leaderboard/schemes/" + id,
      // data: formdata,
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(String(response));
        if (response.status === 200) {
          alert("Scheme Deleted");
          if (check === "district") {
            handleDistrictRank();
          } else if (check === "state") {
            handleStateRank();
          } else if (check === "national") {
            handleNationalRank();
          }
        }
      })
      .catch((err) => {
        console.log(String(err));
        if (err.response.status === 400) {
          alert(err.response.data);
        }
        if (err.response.status === 404) {
          alert("Job not found");
        }
      });
  };

  return (
    <>
      <div>
        <div
          className="col-md-12 list-group-item view-schemes-container"
          key={id}
        >
          <div className="row">
            <div className={check === "national" ? "col-md-4" : "col-md-3"}>
              ID : {id}
            </div>
            {check === "district" && districts !== [] ? (
              <div className="col-md-3">
                {districts && districts.join(", ")}
              </div>
            ) : check === "state" && states !== [] ? (
              <div className="col-md-3">{states && states.join(", ")}</div>
            ) : null}

            <div className={check === "national" ? "col-md-4" : "col-md-3"}>
              {startDate !== null ? startDate.slice(0, -12) : "No start date"}
            </div>
            <div
              className={check === "national" ? "col-md-4" : "col-md-3"}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {endDate !== null ? endDate.slice(0, -12) : "No end date"}
              {schemeEdit && (
                <button
                  className="scheme-edit-submit-button"
                  style={{ position: "relative", zIndex: "1", left: "12px" }}
                  // onClick={() => handleSubmit()}
                  type="submit"
                  form="my-form"
                >
                  Submit
                </button>
              )}
              <button
                style={{ position: "relative", zIndex: "1", left: "16px" }}
                className="scheme-edit-button"
                onClick={() => handleEdit(id)}
              >
                {schemeEdit ? "Cancel" : "Edit"}
              </button>
              <button
                className="delete-button-menu"
                style={{ position: "relative", zIndex: "1", left: "20px" }}
                onClick={() => handleSchemeDelete(id)}
                // onClick={() => setDeleteScheme(!deleteScheme)}
              >
                <i class="fa fa-minus-circle"></i>
                {/* <i class="fa fa-ellipsis-v"></i> */}
              </button>
            </div>
            {deleteScheme && (
              <div className="delete-button-area">
                <button
                  className="delete-button-menu-second"
                  // onClick={() => handleSchemeDelete(id)}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
          {/* {handleSchemeEdit} */}
        </div>

        <div
          style={{ marginBottom: "100px", position: "relative", top: "50px" }}
        >
          {schemeEdit && (
            <div
              style={{
                borderBottom: "1px solid #d2d2d2",
                borderLeft: "1px solid #d2d2d2",
                borderRight: "1px solid #d2d2d2",
                paddingBottom: "40px",
              }}
            >
              {" "}
              <form id="my-form" onSubmit={handleSubmit}>
                <div
                  className="newscheme-date-container"
                  style={{ display: "block" }}
                >
                  <p style={{ display: "inline-block", marginRight: "120px" }}>
                    Active Date Range
                  </p>
                  <div
                    className="newscheme-date-container-input"
                    style={{ display: "inline-block" }}
                  >
                    {items && postData && (
                      <input
                        className="newscheme-date-container-input-date"
                        type="date"
                        required
                        value={dateConverter(String(items.start_date))}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setItems({ ...items, start_date: e.target.value });
                          setPostData({
                            ...postData,
                            start_date: e.target.value,
                          });
                        }}
                      />
                    )}
                    {items && (
                      <input
                        className="newscheme-date-container-input-date"
                        type="date"
                        required
                        value={dateConverter(String(items.end_date))}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setItems({ ...items, end_date: e.target.value });
                          setPostData({
                            ...postData,
                            end_date: e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
                {check === "district" ? (
                  <div
                    className="newscheme-location-container"
                    style={{ display: "inline-block" }}
                  >
                    <p
                      style={{ display: "inline-block", marginRight: "100px" }}
                    >
                      Location Constraint
                    </p>
                    <div style={{ display: "inline-block" }}>
                      <h6 style={{ marginLeft: "37px" }}>Choose District/s</h6>
                      {items && (
                        <Select
                          isMulti
                          name="segment"
                          options={ReactSession.get("districts")}
                          value={
                            items && locationDataConverter(items.seg_mapping)
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            console.log(e);
                            setItems({ ...items, seg_mapping: e });
                            const arr = e.map((item, idx) => {
                              console.log("locationItems", item);
                              return item.value;
                            });

                            let difference = arr.filter(
                              (x) => !uniqueIds.includes(x)
                            );

                            const oldArray = [...districtIds];
                            console.log("oldArray", oldArray);
                            const newArray = [...arr];
                            console.log("newArray", newArray);

                            const deletedArray = oldArray.filter(
                              ({ orignalId: id1 }) =>
                                !newArray.some((id2) => id2 === id1)
                            );

                            console.log("deletedArray", deletedArray);
                            console.log("arraygivingduplicate", difference);
                            setPostData({
                              ...postData,
                              new_segment_ids: difference,
                              deleted_segment_ids: deletedArray.map(
                                (item) => item.fakeId
                              ),
                            });
                            // console.log(arr);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ) : check === "state" ? (
                  <div
                    className="newscheme-location-container"
                    style={{ display: "inline-block" }}
                  >
                    <p
                      style={{ display: "inline-block", marginRight: "100px" }}
                    >
                      Location Constraint
                    </p>
                    <div style={{ display: "inline-block" }}>
                      <h6 style={{ marginLeft: "38px" }}>Choose State/s</h6>
                      <Select
                        isMulti
                        name="segment"
                        options={ReactSession.get("states")}
                        value={
                          items && locationDataConverter(items.seg_mapping)
                        }
                        className={
                          check === "state"
                            ? "basic-multi-select-state"
                            : "basic-multi-select"
                        }
                        classNamePrefix="select"
                        onChange={(e) => {
                          console.log(e);
                          setItems({ ...items, seg_mapping: e });
                          const arr = e.map((item, idx) => {
                            console.log("locationItems", item);
                            return item.value;
                          });

                          let difference = arr.filter(
                            (x) => !uniqueIds.includes(x)
                          );
                          let arr2 = [];
                          let deletedArray = [];
                          if (arr.length < districtIds.length) {
                            arr2 = e.map((item, idx) => {
                              console.log("locationItems", item);
                              return item.value;
                            });

                            // const oldArray = districtIds.map((item) => {
                            //   return item.fakeId;
                            // });
                            const oldArray = districtIds;
                            console.log("oldArray", oldArray);
                            const newArray = arr2;
                            console.log("newArray", newArray);

                            // deletedArray = oldArray.filter(
                            //   (n) =>
                            //     !newArray.includes(n) || !oldArray.includes(n)
                            // );

                            deletedArray = oldArray.filter(
                              ({ orignalId: id1 }) =>
                                !newArray.some((id2) => id2 === id1)
                            );
                          }

                          console.log("deletedArray", deletedArray);
                          console.log("arraygivingduplicate", difference);
                          setPostData({
                            ...postData,
                            new_segment_ids: difference,
                            deleted_segment_ids: deletedArray.map(
                              (item) => item.fakeId
                            ),
                          });
                          // console.log(arr);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {/* </form> */}
                <hr />
                <div>
                  <div>
                    <div className="row clearfix">
                      <div className="col-md-12 column">
                        <table className="table table-hover" id="tab_logic">
                          <thead>
                            <tr>
                              <th className="text-center"> Rank </th>
                              {columnsArray.map((column, index) => (
                                <th className="text-center" key={index}>
                                  {column}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {rows.map((item, idx) => (
                              <tr key={idx}>
                                <td className="text-center">
                                  {" "}
                                  <div
                                    style={{
                                      margin: "10px",
                                    }}
                                  >
                                    {idx + 1}
                                  </div>{" "}
                                </td>

                                {columnsArray.map((column, index) => (
                                  <td className="text-center" key={index}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "10px",
                                      }}
                                    >
                                      <input
                                        style={{ width: "120px" }}
                                        type="text"
                                        column={column}
                                        placeholder="&#8377;"
                                        value={rows[idx][column]}
                                        index={idx}
                                        className="form-control"
                                        required
                                        onChange={(e) => updateState(e)}
                                      />
                                    </div>
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="rank-buttons">
                          <button
                            onClick={handleAddRow}
                            className="rank-add-button"
                          >
                            Add Row
                          </button>
                          {/* <button
                        onClick={postResults}
                        className="btn btn-success float-right"
                      >
                        Save Results
                      </button> */}
                          {rows.length > 0 && (
                            <button
                              onClick={handleRemoveRow}
                              className="rank-delete-button"
                            >
                              Delete Last Row
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SchemeRow;
