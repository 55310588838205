import React from 'react';
import { Link } from 'react-router-dom';

const TaskList = (props) => {
	var tasks_data;
	var tasks = props.tasks;
	if(tasks.length){
	tasks_data = tasks.map((task) => {
				return (
          <div className="col-md-12 row list-group-item" key={task.id}>
            <div className="col-md-1">ID {task.id}.</div>
            <div className="col-md-1">
              {!task.deleted_by && <p>Stage {task.stage}</p>}
            </div>
            <div className="col-md-2">{task.title}</div>
            {task.description && (
              <div className="col-md-3">
                {task.description.length <= 25
                  ? task.description
                  : task.description.substring(0, 23) + "....."}
              </div>
            )}
            <div className="col-md-2">
              {task.deleted_by ? (
                <p style={{ color: "red" }}>Deleted❌</p>
              ) : task.is_active ? (
                <p style={{ color: "green" }}>Active✔️</p>
              ) : (
                <p style={{ color: "grey" }}>Not Active⌛</p>
              )}
            </div>
            <div className="col-md-1">₹ {task.fixed_amount}</div>
            <div className="col-md-2">
              <Link to={"/task/" + task.id}>View</Link>
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <button>Edit</button> */}
            </div>
          </div>
        );
			});
		}
	else{
		tasks_data = <div className="col-md-12">
						<h4  style={{color: "red"}}>No tasks found!</h4>
					 </div>;
	}
	return (
		<div className="list-group">
			{tasks_data}
		</div>
	);
}

export default TaskList;