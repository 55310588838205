import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker';


const LocationMap = (props) => {
    var lat = props.lat;
    var lon = props.lon;
    const [center] = useState({lat: lat, lng: lon });
    const [zoom] = useState(15);
    return (
        <div style={{ height: '50vh', width: '50vh' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBvHkQxVaCUVREa_Q0Ht3VQs8-_GOdUMVM' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <Marker
            lat={lat}
            lng={lon}
            name="My Marker"
            color="blue"
          />
        </GoogleMapReact>
      </div>
    );
}

export default LocationMap;