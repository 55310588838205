import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';


class Login extends Component{
	constructor(props) {
	    super(props);

	    this.state = {
			phone_no:'',
			otp: '',
			name: '',
			otp_sent: false,
			logged_in: false
		}

		if(typeof ReactSession.get("username") !== 'undefined' && typeof ReactSession.get("access_token") !== 'undefined'){
			console.log(ReactSession.get("username"));
			this.state.logged_in= true;
			this.state.username = ReactSession.get("username");
		}
	    this.handleChange = this.handleChange.bind(this);
		this.handleSubmitNumber = this.handleSubmitNumber.bind(this);
		this.handleSubmitOtp = this.handleSubmitOtp.bind(this);
	}
	
	handleChange = (e)=>{
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}
	handleSubmitNumber = (e)=>{
		e.preventDefault();
		var formdata = new FormData();
		formdata.append('name', this.state.name);
		formdata.append('phone_no', this.state.phone_no);

		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/user/otp?lang=en",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data" },
		}).then((response) => {
			if(response.status === 200){
				this.setState({'otp_sent':true});
				alert('OTP sent!');
			}
		})
		.catch((err) => {
			console.error(String(err));
		});
	}
	handleSubmitOtp = (e)=>{
		e.preventDefault();
		var formdata = new FormData();
		formdata.append('name', this.state.name);
		formdata.append('phone_no', this.state.phone_no);
		formdata.append('otp', this.state.otp);
		formdata.append('lat', '28.7041');
		formdata.append('lon', '77.1025');

		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/user/login?app_id=3",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data" },
		}).then((response) => {
			if(response.status === 200){
				if(response.data.status === "error"){
					alert(response.data.errorMsg);
				}
				else{
					ReactSession.set("access_token", response.data.access_token);
					ReactSession.set("username", this.state.name);
					this.setState({
						username: this.state.name,
						logged_in: true, 
						phone_no:'',
						otp: '',
						name: '',
						otp_sent: false,
					});
					alert('Logged in successfully!');
					console.log("user logged in.", response.data);
					window.location.reload(false);
				}
			}
		})
		.catch((err) => {
			console.error(String(err));
		});
	}
	
	render(){
		if(this.state.logged_in){
			return(
				<div>
					<h5>Already logged in as {this.state.username}</h5>
				</div>
			);
		}
		if(this.state.otp_sent){
			return(
				<div>
					<h5>OTP sent on {this.state.phone_no}</h5>
					<form onSubmit={this.handleSubmitOtp}>
							<div className="form-group">
								<label htmlFor="otp">OTP: </label>
								<input type="number" name="otp" id="otp" className="form-control" onChange={this.handleChange} value={this.state.otp}/>
								<label htmlFor="submit_otp">Submit otp </label>
								<input type="submit" id="submit_otp" className="form-control"/>
							</div>
					</form>
				</div>
			);
		}
		else{
			return(
			    <div>
					<form onSubmit={this.handleSubmitNumber}>
							<div className="form-group">
								<label htmlFor="task">Name: </label>
								<input required autocomplete="on" type="text" name="name" id="task" className="form-control" onChange={this.handleChange} value={this.state.name}/>
								<label htmlFor="phone_no">Phone Number: </label>
								<input autocomplete="on" type="number" name="phone_no" id="phone_no" className="form-control" onChange={this.handleChange} value={this.state.phone_no}/>
								<label htmlFor="submit_phone_no">Submit phone_no </label>
								<input type="submit" id="submit_phone_no" className="form-control"/>
							</div>
					</form>
				</div>
			);
		}
	}
}
export default Login;