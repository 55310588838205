import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';

class FormFieldForm extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	type: '',
	    	question_text: '',
	    	placeholder_text: '',
	    	validation_required: 'True',
			check_uniqueness: 'False',
	    	is_required: 'True',
	    	position: this.props.form_pos,
	    	form_types: [],
	    	form_types_keys: {},
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.load_form_types = this.load_form_types.bind(this);
		this.form_types_options = this.form_types_options.bind(this);
	}

	componentDidMount(){
		this.load_form_types();
	}

	load_form_types = () =>{
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		axios({
			method: "get",
		  	url: global.config.apiGateway.URL+"/admin/form-field-types",
		  	headers: { 
					Authorization: access_token 
				},
		})
		.then((response) =>{
			var form_types = response.data;
			var arr = [];
			var key;
			var keys = {};
			for(key in form_types){
				arr.push(form_types[key]["display_name"]);
				keys[form_types[key]["display_name"]] = key;
			}
			this.setState({
				type: arr[0],
				form_types: arr,
				form_types_keys: keys
			});
		})
		.catch((err)=>{
			console.error(String(err));
		});
	}

	form_types_options = () => {
		var options = '';
		if(this.state.form_types.length){
			options = this.state.form_types.map((form_type) => {
				return (
					<option key={form_type}>{form_type}</option>
				);
			});
		}
		return options;

	}
	
	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();

		var question_text = this.state.question_text.trim();
		var placeholder_text = this.state.placeholder_text.trim();
		this.setState({
			question_text: question_text,
			placeholder_text: placeholder_text,
		})
		console.log(this.state);

		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		var type = this.state.form_types_keys[this.state.type];
		formdata.append('task_id', this.props.task_id);
		formdata.append('question_text', question_text);
		if (placeholder_text.length===0)
			formdata.append('placeholder_text', null);
		else
			formdata.append('placeholder_text', placeholder_text)
		formdata.append('type', type);
		formdata.append('validation_required', this.state.validation_required);
		formdata.append('check_uniqueness', this.state.check_uniqueness);
		formdata.append('is_required', this.state.is_required);
		formdata.append('position', this.state.position);
		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/admin/tasks/"+this.props.task_id+"/form-fields",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(response);
			if(response.status === 200){
				alert('Form Field Created');
				this.props.hide();
				this.props.reload();
				//window.location.reload(false);
			}
		})
		.catch((err) => {
			console.log(err.response);
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Task not found');
			}
		});
	}

	render(){
		return(
			<div className="col-md-12">
				<div className="col-md-3 row">
					<div className="col-md-12">
						<h3>Create Form Field</h3>
					</div>
				</div>
				<form className="form-horizontal col-md-9" onSubmit={this.handleSubmit}>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="question_text" style={{"textAlign":"left"}}>Question Text:</label>
				      <div className="col-md-9">          
				        <textarea required type="text" className="form-control" id="question_text" name="question_text" onChange={this.handleChange} value={this.state.question_text}>
				        </textarea>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="placeholder_text" style={{"textAlign":"left"}}>Placeholder Text:</label>
				      <div className="col-md-9">          
				        <textarea type="text" className="form-control" id="placeholder_text" name="placeholder_text" onChange={this.handleChange} value={this.state.placeholder_text}>
				        </textarea>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="type" style={{"textAlign":"left"}}>Form Field Type:</label>
				      <div className="col-md-9">
					      <select required type="number" className="form-control" id="type" name="type" onChange={this.handleChange} value={this.state.type}>
					        {this.form_types_options()}
					      </select>
					   </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="position" style={{"textAlign":"left"}}>Position:</label>
				      <div className="col-md-9">          
				        <input disabled type="number" className="form-control" id="position" name="position" onChange={this.handleChange} value={this.state.position}/>
				      </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="is_required" style={{"textAlign":"left"}}>Required:</label>
				      <div className="col-md-9">
					      <select required type="text" className="form-control" id="is_required" name="is_required" onChange={this.handleChange} value={this.state.is_required}>
					        <option>True</option>
					        <option>False</option>
					      </select>
					   </div>
				    </div>
				    <div className="form-group">
				      <label className="control-label col-md-3" htmlFor="validation_required" style={{"textAlign":"left"}}>Validation Required:</label>
				      <div className="col-md-9">
					      <select required type="text" className="form-control" id="validation_required" name="validation_required" onChange={this.handleChange} value={this.state.validation_required}>
					        <option>True</option>
					        <option>False</option>
					      </select>
					   </div>
				    </div>
					{(this.state.type==="Integer" || this.state.type==="Kisan Number" || this.state.type==="Merchant Number" || this.state.type==="Numeric" || this.state.type==="Phone Number")&&
						<div className="form-group">
						<label className="control-label col-md-3" htmlFor="check_uniqueness" style={{"textAlign":"left"}}>Only Unique Responses:</label>
						<div className="col-md-9">
							<select required type="text" className="form-control" id="check_uniqueness" name="check_uniqueness" onChange={this.handleChange} value={this.state.check_uniqueness}>
								<option>False</option>
								<option>True</option>
							</select>
						</div>
						</div>
					}
				    <div className="form-group">        
				      <div className="col-md-offset-2 col-md-10">
				        <button type="submit" className="btn btn-default" style={{"float":"right"}}>Add Question</button>
				      </div>
				    </div>
			  </form>
			</div>
		);	
	}
}

export default FormFieldForm;