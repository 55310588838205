import React from 'react';
import { Link } from 'react-router-dom';

const JobCard = (props) => {
	return (
		<div className="col-md-4" id={props.job.id} style={{marginBottom:"5px"}}>
			<div className="list-group-item">
				<h3><small>ID {props.job.id}.</small>  {props.job.title}</h3>
				<h5>{ props.job.description.length < 45
					  ? props.job.description
					  : props.job.description.substring(0,43) + '.....'
					}
				</h5>
				<div style={{"display":"flex", "justifyContent":"space-between"}}>
					<Link to={"/job/"+props.job.id}><b>VIEW JOB</b></Link>
					{props.job.deleted_by
					   ? <p style={{"color":"red"}}>Deleted❌</p>
					   : props.job.is_active
						  ? <p style={{"color":"green"}}>Active✔️</p>
						  : <p style={{"color":"grey"}}>Not Active⌛</p>
					}
					{/* <button>Edit</button> */}
				</div>
			</div>
		</div>
	);
}

export default JobCard;