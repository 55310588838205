import React, { useState } from "react";
import "./panCard.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline.js";
import { useEffect } from "react";

function PanCard(props) {
  return (
    <div className="kycCard">
      <div className="subStatus">
        Status:{" "}
        <span>
          {props.isApprovedPan && props.isApprovedPanDoc ? "Done" : "Not Done"}
        </span>
      </div>
      <div className="kycCard_row1">
        <h4>Pan Number</h4>
        <input
          placeholder="Not Provided"
          defaultValue={props.panNumber}
          readOnly
        />
        {props.isApprovedPan && (
          <span className="approved_text">
            Approved
            <CheckCircleOutlineIcon
              style={{ marginLeft: "6px", fontSize: "20px" }}
            />
          </span>
        )}
        {props.pan === false && <span className="approved_text">Pending</span>}
      </div>
      <div className="kycCard_row2">
        <h4>PAN Card</h4>
        <div
          className="kycCard_imageSubgrid"
          style={{ gridTemplateColumns: "1fr" }}
        >
          <a href={props?.panCardFront}>
            <img
              src={props?.panCardFront}
              title="Front Photo"
              alt="User's Pan Card frontside"
            />
          </a>
        </div>
        {props.isApprovedPanDoc === null && (
          <div className="address_approveControls">
            <button
              onClick={() => {
                props.approveDoc(4, props.isApprovedPanDoc);
              }}
              className="button_approve"
            >
              Approve
            </button>
            <button
              onClick={() => {
                props.rejectDoc(4, props.isApprovedPanDoc);
              }}
              className="button_reject"
            >
              Reject
            </button>
          </div>
        )}
        {props.isApprovedPanDoc && (
          <div>
            <span className="approved_text">
              Approved{" "}
              <CheckCircleOutlineIcon
                style={{ fontSize: "20px", marginLeft: "6px" }}
              />
            </span>
          </div>
        )}
        {props.isApprovedPanDoc === false && (
          <span className="approved_text">Rejected</span>
        )}
      </div>
    </div>
  );
}

export default PanCard;
