import React, { useState, useRef, useCallback } from "react";

import useSearch from "./useSearch";
import "./SearchBar.css";

const SearchBar = () => {
  const [text, setText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const { loading, error, suggestions, hasMore } = useSearch(text, pageNumber);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
      console.log(node);
    },
    [loading, hasMore]
  );

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 300);
    };
  };

  function handleChange(e) {
    setText(e.target.value);
    setPageNumber(1);
  }

  function handleHide() {
    setText("");
  }

  document.addEventListener("click", handleHide);

  const optimizedVersion = useCallback(debounce(handleChange), []);

  return (
    <div className="search">
      <input
        className="search-input"
        type="text"
        onChange={optimizedVersion}
      ></input>
      {suggestions.length !== 0 && (
        <div className="data-result">
          {suggestions.map((value, index) => {
            if (suggestions.length === index + 1) {
              return (
                <div className="data-item" ref={lastElementRef} key={index}>
                  <a
                    className="component-link"
                    href={"/partner_profile/" + value.userId}
                  >
                    {value.name}
                  </a>
                </div>
              );
            } else {
              return (
                <div className="data-item" key={index}>
                  <a
                    className="component-link"
                    href={"/partner_profile/" + value.userId}
                  >
                    {value.name}
                  </a>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
