const dev = {
    apiGateway: {
        URL: "https://dev.api.partner.krishi.network"
    }
};
  
const prod = {
    apiGateway: {
        URL: "https://api.partner.krishi.network"
    }
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default config;