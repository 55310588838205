import React from "react";
import "./bankDetails.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline.js";

function BankDetails(props) {
  
  return (
    <div className="kycCard">
      <div className="subStatus">
        Status:{" "}
        <span>
          {props.isApprovedBank !== "Rejected" &&
          props.isApprovedUpi &&
          props.isApprovedCC &&
          props.isApprovedPassbook
            ? "Done"
            : "Not Done"}
        </span>
        {/* {console.log(props.payment_method_id)} */}
      </div>
      <div className="kycCard_subRow1">
        <div className="kycCard_bankDetails">
          <h4>Account Holder Name</h4>
          <input
            placeholder="Not Provided"
            type="text"
            defaultValue={props.ah_name}
            readOnly
          />
          <h4>Account Number</h4>
          <input
            placeholder="Not Provided"
            type="text"
            defaultValue={props.ah_number}
            readOnly
          />
          <h4>Branch Name</h4>
          <input
            placeholder="Not Provided"
            type="text"
            defaultValue={props.branch}
            readOnly
          />
          <h4>IFSC Code</h4>
          <input
            placeholder="Not Provided"
            type="text"
            defaultValue={props.ifsc}
            readOnly
          />
        </div>
        {/* {console.log(props.user_id)} */}
        <div>
          {props.isApprovedBank === "Accepted" && (
            <span className="approved_text">
              Approved{" "}
              <CheckCircleOutlineIcon
                style={{ fontSize: "20px", marginLeft: "6px" }}
              />
            </span>
          )}
          {props.isApprovedBank === "Pending" && (
            <div className="address_approveControls">
              <button
                onClick={() =>
                  props.updateBankVerification(
                    props.payment_method_id,
                    true,
                    props.user_id
                  )
                }
                className="button_approve"
              >
                Approve
              </button>
              <button
                onClick={() =>
                  props.updateBankVerification(
                    props.payment_method_id,
                    false,
                    props.user_id
                  )
                }
                className="button_reject"
              >
                Reject
              </button>
            </div>
          )}
          {props.isApprovedBank === "Rejected" && (
            <span className="approved_text">Rejected </span>
          )}
          {(props.isApprovedBank === undefined ||
            props.isApprovedBank === "No entry") && (
            <span className="approved_text">No details specified </span>
          )}
        </div>
      </div>
      {/* {console.log(props.isApprovedBank)} */}
      <div className="kycCard_row2">
        <h4>Cancelled Check</h4>
        {props.cancelledCheck && (
          <div
            className="kycCard_imageSubgrid"
            style={{ gridTemplateColumns: "1fr" }}
          >
            <a href={props?.cancelledCheck}>
              <img
                src={props.cancelledCheck}
                title="Front Photo"
                alt="User's Cancelled Check frontside"
                style={{ margin: "auto" }}
              />
            </a>
          </div>
        )}
        {props.cancelledCheck && props.isApprovedCC === null && (
          <div className="address_approveControls">
            <button
              onClick={() => props.approveDoc(2, props.isApprovedCC)}
              className="button_approve"
            >
              Approve
            </button>
            <button
              onClick={() => props.rejectDoc(2, props.isApprovedCC)}
              className="button_reject"
            >
              Reject
            </button>
          </div>
        )}
        {props.cancelledCheck && props.isApprovedCC && (
          <span className="approved_text">
            Approved{" "}
            <CheckCircleOutlineIcon
              style={{ fontSize: "20px", marginLeft: "6px" }}
            />
          </span>
        )}
        {props.cancelledCheck && props.isApprovedCC === false && (
          <span className="approved_text">Rejected</span>
        )}
      </div>
      <div className="kycCard_row2">
        <h4>Passbook</h4>
        <div
          className="kycCard_imageSubgrid"
          style={{ gridTemplateColumns: "1fr" }}
        >
          {props.passbook && (
            <a href={props.passbook}>
              <img
                src={props.passbook}
                title="Front Photo"
                alt="User's Passbook frontside"
                style={{ margin: "auto" }}
              />
            </a>
          )}
        </div>
        {props.passbook && props.isApprovedPassbook == null && (
          <div className="address_approveControls">
            <button
              onClick={() => props.approveDoc(3, props.isApprovedPassbook)}
              className="button_approve"
            >
              Approve
            </button>
            <button
              onClick={() => props.rejectDoc(3, props.isApprovedPassbook)}
              className="button_reject"
            >
              Reject
            </button>
          </div>
        )}
        {props.passbook && props.isApprovedPassbook && (
          <span className="approved_text">
            Approved{" "}
            <CheckCircleOutlineIcon
              style={{ fontSize: "20px", marginLeft: "6px" }}
            />
          </span>
        )}
        {props.passbook && props.isApprovedPassbook === false && (
          <span className="approved_text">Rejected</span>
        )}
      </div>
      <div className="kycCard_row1">
        <h4>UPI ID</h4>
        <input placeholder="Not Provided" defaultValue={props.upi} readOnly />
        {props.isApprovedUpi && (
          <span className="approved_text">
            Approved
            <CheckCircleOutlineIcon
              style={{ marginLeft: "6px", fontSize: "20px" }}
            />
          </span>
        )}
        {!props.isApprovedUpi && <span className="approved_text">Pending</span>}
      </div>
    </div>
  );
}

export default BankDetails;
