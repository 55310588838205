import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import moment from 'moment';

class JobInstance extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	job_instance_id: props.match.params.job_instance_id,
	    	found: false,
	    	logged_in: false,
	    	job_data: null,
		};

		if(typeof ReactSession.get("username") !== 'undefined' && typeof ReactSession.get("access_token") !== 'undefined'){
			this.state.logged_in= true;
			this.state.username = ReactSession.get("username");
		}
		this.fetch_job = this.fetch_job.bind(this);
		this.generate_job_data = this.generate_job_data.bind(this);
	}
	componentDidMount(){
		if(this.state.logged_in)
			this.fetch_job();
	}

	fetch_job = () => {
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var data = [];
		axios.get(global.config.apiGateway.URL+"/admin/jobs/instances/"+this.state.job_instance_id,
		{ 
			headers: { Authorization: access_token },

		})
		.then((response) => {
			data = response.data;
			this.setState({
				job_data: data,
				found: true,
			});
		})
		.catch((error)=>{
			console.error(String(error));
		});	
	}

	generate_job_data = () => {
		var job_data;
		job_data =	<div className="col-md-10 row">
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Job Title :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.job_data.title}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Job Instance ID :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.job_data.id}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>User ID :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.job_data.user_id}</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>User :</h4> 
							</div>
							<div className="col-md-8">
								<h4>{this.state.job_data.user_name} <small><Link to={"/partner_profile/"+this.state.job_data.user_id}>view profile</Link></small></h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Start Time :</h4> 
							</div>
							<div className="col-md-8">
								<h5>
									{moment(this.state.job_data.start_time).local().format("LLL")}
								</h5>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>End Time :</h4> 
							</div>
							<div className="col-md-8">
								<h5>
									{this.state.job_data.end_time
										? moment(this.state.job_data.end_time).local().format("LLL")
										: "Not ended"
									}
								</h5>
							</div>
						</div>
						<div className="col-md-12 row" style={{"color":"green"}}>
							<div className="col-md-4">
							 	<h4>Tasks Approved :</h4> 
							</div>
							<div className="col-md-8">
								<h4>
								{this.state.job_data.number_approved}
								</h4>
							</div>
						</div>
						<div className="col-md-12 row" style={{"color":"red"}}>
							<div className="col-md-4">
							 	<h4>Tasks Rejected :</h4> 
							</div>
							<div className="col-md-8">
								<h4>
								{this.state.job_data.number_rejected}
								</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Tasks Pending for Approval :</h4> 
							</div>
							<div className="col-md-8">
								<h4>
								{this.state.job_data.number_pending}
								</h4>
							</div>
						</div>
						<div className="col-md-12 row">
							<div className="col-md-4">
							 	<h4>Incomplete Task Instances :</h4> 
							</div>
							<div className="col-md-8">
								<h4>
								{this.state.job_data.number_incomplete}
								</h4>
							</div>
						</div>
					</div>
		return job_data;
	}

	render(){
		if(!this.state.logged_in){
			return(
				<div>
					<h5>Not logged in !</h5>
				</div>
			);
		}
		else if(!this.state.found){
			return(
				<div>
					<h5>Job Instance {this.state.job_instance_id} not found !</h5>
				</div>
			);
		}
		else{
			var job_data = this.generate_job_data();
			return (
        <div className="row">
          <div className="col-md-12 page-header text-center">
            <h1>
              <small>
                <Link to={"/job/" + this.state.job_data.job_id}>
                  Job ID - {this.state.job_data.job_id}
                </Link>
              </small>{" "}
              Job Instance - {this.state.job_data.id}{" "}
            </h1>
          </div>
          <div className="col-md-12 row">
            {job_data}
            <div className="col-md-2 row">
              <div className="col-md-12" style={{ margin: "5px" }}>
                <Link
                  to={
                    "/job_instance/" +
                    this.state.job_instance_id +
                    "/task_instances?page=1&status=all"
                  }
                >
                  View Task Instances
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
		}
	}

}

export default JobInstance;