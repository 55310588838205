import React, { useEffect, useState } from "react";
import "./leaderboard.css";
import Select from "react-select";
import axios from "axios";
import { ReactSession } from "react-client-session";
import SchemeRow from "./SchemeRow";

const Leaderboard = () => {
  const [addSchemeActive, setAddSchemeActive] = useState(false);
  const [rows, setRows] = useState([{}]);
  const [items, setItems] = useState([]);
  // const [editChecker, setEditChecker] = useState(false);
  const [buttonState, setButtonState] = useState({
    buttonOne: true,
    buttonTwo: false,
    buttonThree: false,
  });
  const [check, setCheck] = useState("district");
  const [searchTerm, setSearchTerm] = useState("");
  const [postData, setPostData] = useState({
    leaderboard_scheme_id: "",
    rank_in: "",
    start_date: "",
    segment_ids: [],
    end_date: "",
    modified_ranks: [],
    deleted_segment_ids: [],
    new_segment_ids: [],
    prizes: [],
  });

  const columnsArray = ["Daily", "Weekly", "Monthly"];

  var access_token = "Bearer " + ReactSession.get("access_token");

  useEffect(() => {
    const getData = async () => {
      const res = await fetch(
        global.config.apiGateway.URL + `/admin/leaderboard/schemes?rank_in=1`,
        {
          headers: {
            Authorization: access_token,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      console.log(data);
      const resultList = data;
      setItems(resultList);
      console.log(resultList);
      console.log(items);
    };
    getData();
  }, []);

  const handleDistrictRank = async () => {
    setCheck("district");
    setItems([]);
    setButtonState({
      buttonOne: true,
      buttonTwo: false,
      buttonThree: false,
    });
    const res = await fetch(
      global.config.apiGateway.URL + `/admin/leaderboard/schemes?rank_in=1`,
      {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);
    const resultList = data;
    setItems(resultList);
    setAddSchemeActive(false);
    // setSchemeEdit(false);
    console.log(resultList);
    console.log(items);
  };

  const handleStateRank = async () => {
    setCheck("state");
    setItems([]);
    setButtonState({
      buttonOne: false,
      buttonTwo: true,
      buttonThree: false,
    });
    const res = await fetch(
      global.config.apiGateway.URL + `/admin/leaderboard/schemes?rank_in=2`,
      {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);
    const resultList = data;
    setItems(resultList);
    setAddSchemeActive(false);
    // setSchemeEdit(false);
    console.log(resultList);
    console.log(items);
  };
  const handleNationalRank = async () => {
    setCheck("national");
    setItems([]);
    setButtonState({
      buttonOne: false,
      buttonTwo: false,
      buttonThree: true,
    });
    const res = await fetch(
      global.config.apiGateway.URL + `/admin/leaderboard/schemes?rank_in=3`,
      {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);
    const resultList = data;
    setItems(resultList);
    setAddSchemeActive(false);
    // setSchemeEdit(false);
    console.log(resultList);
    console.log(items);
  };

  const handleAddRow = () => {
    const item = {};
    setRows([...rows, item]);
  };

  // const postResults = () => {
  //   console.log(rows);
  // };

  const handleRemoveRow = () => {
    setRows((currentRow) => [...currentRow.slice(0, -1)]);
  };

  const updateState = (e) => {
    let prope = e.target.attributes.column.value;
    let index = e.target.attributes.index.value;
    let fieldValue = e.target.value;

    const tempRows = [...rows];
    const tempObj = rows[index];
    tempObj[prope] = fieldValue;

    tempRows[index] = tempObj;
    setRows(tempRows);

    const mainRows = rows.map((item, idx) => {
      return {
        daily_reward: item.Daily,
        monthly_reward: item.Monthly,
        position: idx + 1,
        weekly_reward: item.Weekly,
      };
    });
    // console.log(mainRows);
    setPostData({ ...postData, prizes: mainRows });

    console.log(postData);
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  // const handleSchemeEdit = async (id) => {
  //   console.log(id);
  //   // setSchemeEdit(true);

  //   const res = await fetch(
  //     global.config.apiGateway.URL + `/admin/leaderboard/schemes/${id}`,
  //     {
  //       headers: {
  //         Authorization: access_token,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );
  //   const data = await res.json();
  //   console.log(data);
  //   const resultList = data;
  //   // setItems(resultList);
  //   console.log(resultList);
  //   // console.log(items);
  //   // if (id === 44) {
  //   // setSchemeEdit(true);
  //   // }
  //   // return <div>Hello</div>
  // };

  const addNewSchemeHandler = (e) => {
    e.preventDefault();
    console.log(postData);
    axios({
      method: "put",
      url: global.config.apiGateway.URL + "/admin/leaderboard/schemes",
      data: postData,
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          alert("New Scheme Created");
        }
        // window.location.reload()
      })
      .catch((error) => {
        console.error(String(error));
      });
  };

  // const handleEdit = (id) => {
  //   const filterItems = items.filter((item) => item.id !== id);
  //   const selectedItem = items.find((item) => item.id === id);
  //   setItems(filterItems);
  //   // setSchemeEdit(true);
  // };

  const handleNewSchemeCreation = (e) => {
    e.preventDefault();
    const d1 = new Date(postData.start_date);
    const d2 = new Date(postData.end_date);
    if (d1 > d2) {
      alert("Start date cannot be greater than end date!");
      return;
    }
    console.log(postData);
    axios({
      method: "put",
      url: global.config.apiGateway.URL + "/admin/leaderboard/schemes",
      data: {
        ...postData,
        rank_in: check === "district" ? 1 : check === "state" ? 2 : 3,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          alert("New Scheme Created");
        }
        // window.location.reload()
        if (check === "district") {
          handleDistrictRank();
        } else if (check === "state") {
          handleStateRank();
        } else if (check === "national") {
          handleNationalRank();
        }
        setAddSchemeActive(false);
        setRows([]);
      })
      .catch((error) => {
        console.error(String(error));
      });
    // console.log(id);
  };

  return (
    <>
      <div className="container">
        <div className="rank-container">
          <button
            className={
              buttonState.buttonOne
                ? "rank-container-boxes-afterClick"
                : "rank-container-boxes"
            }
            autoFocus
            onClick={handleDistrictRank}
          >
            District Rank
          </button>
          <button
            className={
              buttonState.buttonTwo
                ? "rank-container-boxes-afterClick"
                : "rank-container-boxes"
            }
            onClick={handleStateRank}
          >
            State Rank
          </button>
          <button
            className={
              buttonState.buttonThree
                ? "rank-container-boxes-afterClick"
                : "rank-container-boxes"
            }
            onClick={handleNationalRank}
          >
            National Rank
          </button>
        </div>
        <div className="search-area">
          <input
            className="search-input-area"
            type="text"
            placeholder="Search scheme ID"
            name="searchTerm"
            onChange={handleSearchTerm}
            value={searchTerm}
          />
          <button
            className="new-scheme-button"
            type="submit"
            onClick={() => {
              setAddSchemeActive(true);
            }}
          >
            Add new scheme
          </button>
        </div>
        {addSchemeActive && (
          <div className="newsheme-container">
            <form onSubmit={handleNewSchemeCreation}>
              <div className="newshceme-form-button-container">
                <button className="newshceme-form-buttonOne" type="submit">
                  {/* <button
                className="newshceme-form-buttonOne"
                onClick={handleNewSchemeCreation}
              > */}
                  Add Scheme
                </button>
                <button
                  className="newshceme-form-buttonTwo"
                  onClick={() => setAddSchemeActive(false)}
                >
                  Discard
                </button>
              </div>
              <hr />
              <div className="newscheme-date-container">
                <p>Active Date Range</p>
                <div className="newscheme-date-container-input">
                  <input
                    className="newscheme-date-container-input-date"
                    type="date"
                    required
                    onChange={(e) => {
                      setPostData({ ...postData, start_date: e.target.value });
                    }}
                  />
                  <input
                    className="newscheme-date-container-input-date"
                    type="date"
                    required
                    onChange={(e) =>
                      setPostData({ ...postData, end_date: e.target.value })
                    }
                  />
                </div>
              </div>
              {check === "district" ? (
                <div className="newscheme-location-container">
                  <p>Location Constraint</p>
                  <div>
                    <h6 style={{ marginLeft: "37px" }}>Choose District/s</h6>
                    <Select
                      isMulti
                      name="segment"
                      options={ReactSession.get("districts")}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        const arr = e.map((item, idx) => {
                          return item.value;
                        });
                        console.log(arr);
                        setPostData({ ...postData, segment_ids: arr });
                      }}
                    />
                  </div>
                </div>
              ) : check === "state" ? (
                <div className="newscheme-location-container">
                  <p>Location Constraint</p>
                  <div>
                    <h6 style={{ marginLeft: "38px" }}>Choose State/s</h6>
                    <Select
                      isMulti
                      name="segment"
                      options={ReactSession.get("states")}
                      className={
                        check === "state"
                          ? "basic-multi-select-state"
                          : "basic-multi-select"
                      }
                      classNamePrefix="select"
                      onChange={(e) => {
                        const arr = e.map((item, idx) => {
                          return item.value;
                        });
                        console.log(arr);
                        setPostData({ ...postData, segment_ids: arr });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {/* </form> */}
              <hr />
              <div className="add-rank-container">
                <div>
                  <div className="row clearfix">
                    <div className="col-md-12 column">
                      <table className="table table-hover" id="tab_logic">
                        <thead>
                          <tr>
                            <th className="text-center"> Rank </th>
                            {columnsArray.map((column, index) => (
                              <th className="text-center" key={index}>
                                {column}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((item, idx) => (
                            <tr key={idx}>
                              <td className="text-center">
                                {" "}
                                <div
                                  style={{
                                    margin: "10px",
                                  }}
                                >
                                  {idx + 1}
                                </div>{" "}
                              </td>

                              {columnsArray.map((column, index) => (
                                <td className="text-center" key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      margin: "10px",
                                    }}
                                  >
                                    <input
                                      style={{ width: "120px" }}
                                      type="text"
                                      column={column}
                                      placeholder="&#8377;"
                                      value={rows[idx][column]}
                                      index={idx}
                                      className="form-control"
                                      required
                                      onChange={(e) => {
                                        updateState(e);
                                      }}
                                    />
                                  </div>
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="rank-buttons">
                        <button
                          onClick={handleAddRow}
                          className="rank-add-button"
                        >
                          Add Row
                        </button>
                        {/* <button
                        onClick={postResults}
                        className="btn btn-success float-right"
                      >
                        Save Results
                      </button> */}
                        {rows.length > 1 && (
                          <button
                            onClick={handleRemoveRow}
                            className="rank-delete-button"
                          >
                            Delete Last Row
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="col-md-12 view-schemes-container-heading">
          {check === "district" ? (
            <div>
              <div className="col-md-3">
                <h5>Schemes</h5>
              </div>
              <div className="col-md-3">
                <h5>District</h5>
              </div>
              <div className="col-md-3">
                <h5>Start Date</h5>
              </div>
              <div className="col-md-3">
                <h5>End Date</h5>
              </div>
            </div>
          ) : check === "state" ? (
            <div>
              <div className="col-md-3">
                <h5>Schemes</h5>
              </div>
              <div className="col-md-3">
                <h5>State</h5>
              </div>
              <div className="col-md-3">
                <h5>Start Date</h5>
              </div>
              <div className="col-md-3">
                <h5>End Date</h5>
              </div>
            </div>
          ) : (
            <div>
              <div className="col-md-4">
                <h5>Schemes</h5>
              </div>
              <div className="col-md-4">
                <h5>Start Date</h5>
              </div>
              <div className="col-md-4">
                <h5>End Date</h5>
              </div>
            </div>
          )}
        </div>
        <div>
          {items
            .filter((item) => {
              if (searchTerm === "") {
                return item;
              } else if (
                item.id
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return item;
              }
              return null;
            })
            .sort((a, b) => b.id - a.id)
            .map((item, index) => {
              return (
                <div>
                  {item.is_active && (
                    <SchemeRow
                      key={index}
                      check={check}
                      id={item.id}
                      districts={item.districts}
                      states={item.states}
                      startDate={item.start_date}
                      endDate={item.end_date}
                      handleNationalRank={handleNationalRank}
                      handleStateRank={handleStateRank}
                      handleDistrictRank={handleDistrictRank}
                      setAddSchemeActiveClose={setAddSchemeActive}
                      // schemeEdit={schemeEdit}
                      // setSchemeEdit={setSchemeEdit}

                      // handleEdit={() => handleEdit(item.id)}
                    />
                  )}
                </div>
              );
              {
              }
            })}
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
