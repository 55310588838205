import React from 'react';

const JobSegmentList = (props) => {
	const list = props.segments.map((seg)=>{
		return(
			<div className="col-md-12 list-group-item row" style={{"margin":"5px"}} key={seg.id}>
				<div className="col-md-2">
					<b>Constraint ID {seg.id}.</b> 
				</div>
				<div className="col-md-2">
					Constraint Over : {seg.type}
				</div>
				<div className="col-md-3">
					Constraint : {seg.segment_name}.
				</div>
				<div className="col-md-2">
							{seg.is_active
								? <p style={{"color":"green"}}>Active✔️</p>
								: <p style={{"color":"red"}}>Deleted❌</p>
							 }
				</div>
				{seg.is_active&&
					<div className="col-md-3">
						<button className="btn btn-danger btn-sm" id={seg.id} onClick={props.delete_job_segment}>Delete</button>
					</div>
				}
			</div>
		);
	});
	return (list);
}

export default JobSegmentList;