import LocationMap from './map/location_map';
import moment from "moment";

const FormField = (props) => {
	var data = props.form_data;
	return (
		<div className="col-md-12 row list-group-item">
			<div className="col-md-4">
				Question : {data.question_text}
			</div>
			<div className="col-md-4">
				Type : {data.type}
			</div>
			<div className="col-md-4">
				Created At : {moment(data.created_at).local().format("LLL")}
			</div>
			{data.value_string !== null&&
				<div className="col-md-12">
					Answer : {(data.type==="image" || data.type==="front_camera_image" || data.type==="back_camera_image")
										? <a href={data.value_string} rel="noopener noreferrer" target="_blank" style={{"margin-left":"10px"}}><img className="img-responsive" src={data.value_string} alt="Response" style={{"display": "inline", "height":"400px", "width":"400px"}}/></a>
										: (data.type==="video" || data.type==="front_camera_video" || data.type==="back_camera_video")
											? <div className="video_player">
												<video src={data.value_string} height="400px" width="400px" controls></video>
											  </div>
											: <b><p style={{"display": "inline"}}>{data.value_string}</p></b>
									}
				{data.geo_lat != null && data.geo_lon != null &&
					<LocationMap lat={data.geo_lat} lon={data.geo_lon}/>
				}
				</div>
			}
			{data.value_numeric !== null&&
				<div className="col-md-12">
					Answer : {data.value_numeric}
				</div>
			}
		</div>
	);
}


export default FormField;