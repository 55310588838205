import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import { useHistory } from "react-router-dom";
import "./kycDetails.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack.js";
import AddressProof from "./addressProof";
import PanCard from "./panCard";
import BankDetails from "./bankDetails";
let _isMounted = true;
function KYC_Details(props) {
  const [userDetails, setUserDetails] = useState();
  const [details, setDetails] = useState(); //kyc details
  const [documents, setDocuments] = useState(); //kyc documents
  let [addressDoc, setAddressDoc] = useState();
  let [bankDoc, setBankDoc] = useState();
  const [panDoc, setPanDoc] = useState();
  const [haveDl, setHaveDl] = useState(false);
  const [haveVoterId, setHaveVoterId] = useState(false);
  const [subPage, setSubPage] = useState("address_page");
  const access_token = "Bearer " + ReactSession.get("access_token");
  const params = {
    user_id: props.match.params.user_id,
  };
  useEffect(() => {
    if (documents) {
      for (let i = 0; i < documents.length; i++) {
        if (
          documents[i].document_type === 2 ||
          documents[i].document_type === 3
        ) {
          // have bank details (2=blank cheque, 3=passbook)
          setBankDoc(documents[i]);
        }
        if (documents[i].document_type === 4) {
          // have Pan
          setPanDoc(documents[i]);
        }
        if (documents[i].document_type === 5) {
          // have Driving Licence
          setHaveDl(true);
          if (!addressDoc) {
            setAddressDoc(documents[i]);
          }
        }
        if (documents[i].document_type === 6) {
          // have Voter Id
          if (!addressDoc) {
            setAddressDoc(documents[i]);
          }
          setHaveVoterId(true);
        }
      }
    }
  }, [documents]);
  useEffect(() => {
    _isMounted = true;
    // call for basic user details (mainly user_name right now)
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/users/" +
          props.match.params.user_id +
          "/details",
        {
          headers: { Authorization: access_token },
          params: params,
        }
      )
      .then((response) => {
        if (_isMounted) {
          setUserDetails(response.data);
        }
        // console.log(response.data);
      })
      .catch((error) => {
        console.error(String(error));
      });
    // call for user kyc info
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/users/" +
          props.match.params.user_id +
          "/kyc-details",
        {
          headers: { Authorization: access_token },
          params: params,
        }
      )
      .then((response) => {
        if (_isMounted) {
          // console.log(response.data);
          setDetails(response.data);
        }
      })
      .catch((error) => {
        console.error(String(error));
      });
    // call for user's kyc documents
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/users/" +
          props.match.params.user_id +
          "/kyc-documents",
        {
          headers: { Authorization: access_token },
          params: params,
        }
      )
      .then((response) => {
        if (_isMounted) {
          setDocuments(response.data);
        }
      })
      .catch((error) => {
        console.error(String(error));
      });
    return () => {
      // clean up for component will unmount
      _isMounted = false;
    };
  }, []);

  useEffect(() => {
    let controls = document.getElementsByClassName("kyc_nav__control");
    for (let i = 0; i < controls.length; i++) {
      controls[i].className = "kyc_nav__control";
      if (controls[i].id === subPage) {
        controls[i].className = "kyc_nav__control selected";
      }
    }
  });
  const history = useHistory();
  let documentsMapping = [
    "Aadhar Card",
    "Blank Cheque",
    "Bank Passbook",
    "PAN Card",
    "Driving License",
    "Voter ID",
  ];
  const approveDoc = (document_type, isApproved) => {
    // isApproved = true if document is already approved
    if (isApproved === null) {
      const confirmMessage =
        "Are you sure to Approve " +
        userDetails?.name +
        "'s " +
        documentsMapping[document_type - 1];
      const isUserConfirmationReceived = window.confirm(confirmMessage);
      if (isUserConfirmationReceived) {
        axios
          .put(
            global.config.apiGateway.URL +
              "/admin/users/" +
              props.match.params.user_id +
              "/kyc-documents/" +
              document_type +
              "/verification",
            null,
            {
              headers: { Authorization: access_token },
              params: {
                status: true,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setDocuments(response.data);
            }
            console.log("approved");
          })
          .catch((error) => {
            console.error(String(error));
          });
      }
    }
  };
  const rejectDoc = (document_type, isApproved) => {
    const confirmMessage =
      "Are you sure to Reject " +
      userDetails?.name +
      "'s " +
      documentsMapping[document_type - 1];
    const isUserConfirmationReceived = window.confirm(confirmMessage);
    if (isApproved == null && isUserConfirmationReceived) {
      axios
        .put(
          global.config.apiGateway.URL +
            "/admin/users/" +
            props.match.params.user_id +
            "/kyc-documents/" +
            document_type +
            "/verification",
          null,
          {
            headers: { Authorization: access_token },
            params: {
              status: false,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setDocuments(response.data);
          }
          console.log("rejected");
        })
        .catch((error) => {
          console.error(String(error));
        });
    }
  };
  const updateBankVerification = (payment_method_id, status, user_id) => {
    const confirmMessage =
      "Are you sure to " + (status ? "Approve" : "Reject") + " Bank Details? ";
    const isUserConfirmationReceived = window.confirm(confirmMessage);
    if (isUserConfirmationReceived) {
      axios
        .put(
          global.config.apiGateway.URL +
            "/admin/payment_methods/" +
            payment_method_id +
            "/verification",
          null,
          {
            headers: { Authorization: access_token },
            params: {
              status: status,
              user_id: user_id,
            },
          }
        )
        .then((response) => {
          setUserDetails(response.data);
          console.log("approved");
        })
        .catch((error) => {
          console.error(String(error));
        });
    }
  };
  return (
    // props.match.params.user_id
    // props.match.params.kyc_check
    <div className="kyc_details">
      <div className="kyc_nameStrip">
        <span style={{ display: "flex" }}>
          <ArrowBackIcon
            style={{ fontSize: "24px", cursor: "pointer", marginRight: "30px" }}
            onClick={() => history.goBack()}
          />
          {userDetails?.name}
        </span>
        <span style={{ opacity: 0.6 }}>
          Status: <span>{props.match.params.kyc_check}</span>
        </span>
      </div>
      <div className="kyc_nav">
        <button
          id="address_page"
          className="kyc_nav__control selected"
          onClick={() => setSubPage("address_page")}
        >
          Address Proof
        </button>
        <button
          id="panCard_page"
          className="kyc_nav__control"
          onClick={() => setSubPage("panCard_page")}
        >
          Pan Card
        </button>
        <button
          id="bankDetails_page"
          className="kyc_nav__control"
          onClick={() => setSubPage("bankDetails_page")}
        >
          Bank Details
        </button>
      </div>
      <div className="subPage_container">
        {subPage === "address_page" ? (
          <AddressProof
            user_id={props.match.params.user_id}
            aadharNumber={details?.aadhar_number}
            isApprovedAadhar={details?.identity}
            driverLicenceFront={
              addressDoc?.document_type === 5 ? addressDoc?.front_image_url : ""
            }
            driverLicenceBack={
              addressDoc?.document_type === 5 ? addressDoc?.back_image_url : ""
            }
            isApprovedDl={addressDoc?.verified}
            haveDl={haveDl}
            voterIdFront={
              addressDoc?.document_type === 6 ? addressDoc?.front_image_url : ""
            }
            voterIdBack={
              addressDoc?.document_type === 6 ? addressDoc?.back_image_url : ""
            }
            isApprovedVoterId={addressDoc?.verified}
            haveVoterId={haveVoterId}
            approveDoc={approveDoc}
            rejectDoc={rejectDoc}
          />
        ) : subPage === "panCard_page" ? (
          <PanCard
            panNumber={details?.pan_number}
            isApprovedPan={details?.pan}
            panCardFront={panDoc?.front_image_url}
            isApprovedPanDoc={panDoc?.verified}
            approveDoc={approveDoc}
            rejectDoc={rejectDoc}
            pan={details?.pan}
          />
        ) : subPage === "bankDetails_page" ? (
          <BankDetails
            ah_name={details?.account_name}
            ah_number={details?.account_number}
            ifsc={details?.ifsc_code}
            isApprovedBank={details?.bank}
            payment_method_id={details?.bank_payment_method_id}
            branch={details?.bank_name}
            isApprovedUpi={details?.upi === "Accepted"}
            upi={details?.upi_id}
            cancelledCheck={
              bankDoc?.document_type === 2 ? bankDoc.front_image_url : ""
            }
            passbook={
              bankDoc?.document_type === 3 ? bankDoc.front_image_url : ""
            }
            isApprovedCC={
              bankDoc?.document_type === 2 ? bankDoc.verified : true
            }
            isApprovedPassbook={
              bankDoc?.document === 3 ? bankDoc.verified : true
            }
            approveDoc={approveDoc}
            rejectDoc={rejectDoc}
            updateBankVerification={updateBankVerification}
            user_id={props.match.params.user_id}
          />
        ) : (
          "null"
        )}
      </div>
    </div>
  );
}

export default KYC_Details;
