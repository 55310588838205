import React from "react";
import * as XLSX from "xlsx/xlsx.mjs";
function DownloadWorkbook({ sheet1JSON, sheet2JSON, filename, className }) {
  const downloadWB = (sheet1JSON, sheet2JSON, filename) => {
    // takes json object, converts them to excel sheets and download them in single workbook(.XLSX) file
    let worksheet1 = XLSX.utils.json_to_sheet(sheet1JSON);
    let worksheet2 = XLSX.utils.json_to_sheet(sheet2JSON, {
      header: [
        "gp_name",
        "gp_phone",
        "task_name",
        "job_name",
        "transaction_id",
        "transaction_amount",
        "transaction_created_at",
      ],
    });

    let workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet1, "Payment Sheet");
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet2,
      "Transaction Task Breakdown"
    );

    XLSX.writeFile(workbook, filename);
  };

  return (
    <button
      className={className}
      onClick={() => downloadWB(sheet1JSON, sheet2JSON, filename)}
    >
      Download XLSX
    </button>
  );
}


export default DownloadWorkbook;
