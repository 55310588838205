import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Select from 'react-select';


class JobSegForm extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	type: '',
	    	segment: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}
	handleSegmentChange = (e) => {
		// console.log(e[0].value);
		const arr = e.map((item, idx)=>{
			return item.value;
		})
		console.log(arr)
		this.setState({segment: e});
	}
	handleSubmit = (e) => {
		e.preventDefault();
		console.log(this.state);
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('job_id', this.props.job_id);
		formdata.append('segment_type', this.state.type);
		this.state.segment.forEach((seg)=>{
			formdata.append('segment_id', seg.value);
		});
		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/admin/jobs/"+this.props.job_id+"/segments",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(response);
			if(response.status === 200){
				alert('Job Segment Created');
				this.props.reload();
			}
		})
		.catch((err) => {
			console.error(err.response);
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Job not found');
			}
		});
	}


	render(){
			return(
				<div className="col-md-12 row"  >
					<div className="col-md-4 row">
						<div className="col-md-12">
							<h4>Add Location Constraint</h4>
						</div>
					</div>
					<form className="form-horizontal col-md-8" onSubmit={this.handleSubmit}>
					    <div className="form-group">
					      <label className="control-label" htmlFor="type">Add Constraint Over: </label>        
					        <select required type="text" className="form-control" id="type" name="type" onChange={this.handleChange} value={this.state.type}>
					        <option></option>
							<option>state</option>
					        <option>district</option>
					        <option>sub_division</option>
					        <option>village</option>
					      </select>		
					    </div>
					    {this.state.type === "state"&&
					    	<Select
							    isMulti
							    name="segment"
							    options={ReactSession.get("states")}
							    className="basic-multi-select"
							    classNamePrefix="select"
							    onChange ={this.handleSegmentChange}
							  />
					    }
					    {this.state.type === "district"&&
					    	<Select
							    isMulti
							    name="segment"
							    options={ReactSession.get("districts")}
							    className="basic-multi-select"
							    classNamePrefix="select"
							    onChange ={this.handleSegmentChange}
							  />
					    }
					    {this.state.type === "sub_division"&&
					    	<Select
							    isMulti
							    name="segment"
							    options={ReactSession.get("sub_divisions")}
							    className="basic-multi-select"
							    classNamePrefix="select"
							    onChange ={this.handleSegmentChange}
							  />
					    }
					    {this.state.type === "village"&&
					    	"village"
					    }
					    <div className="form-group" style={{"marginTop":"10px"}}>        
					        <button type="submit" className="btn btn-default" style={{float:"right"}}>Add Constraint</button>
					    </div>
				  </form>
				</div>
			);
	}
}

export default JobSegForm;