import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Select from 'react-select';


class SchemeSegForm extends Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	display_form: false,
	    	type: '',
	    	segment: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam] : val
		});
	}
	handleSegmentChange = (e) => {
		this.setState({segment: e});
	}
	handleSubmit = (e) => {
		e.preventDefault();
		console.log(this.state);
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var formdata = new FormData();
		formdata.append('scheme_id', this.props.scheme_id);
		formdata.append('segment_type', this.state.type);
		this.state.segment.forEach((seg)=>{
			formdata.append('segment_id', seg.value);
		});
		axios({
		  method: "post",
		  url: global.config.apiGateway.URL+"/admin/schemes/"+this.props.scheme_id+"/segments",
		  data: formdata,
		  headers: { "Content-Type": "multipart/form-data",
		  			  Authorization: access_token
		  		   },
		}).then((response) => {
			console.log(response);
			if(response.status === 200){
				alert('Scheme Segment Created');
				this.setState({display_form:false});
				this.props.reload();
			}
		})
		.catch((err) => {
			console.error(err.response);
			if(err.response.status === 400){
				alert(err.response.data);
			}
			if(err.response.status === 404){
				alert('Scheme not found');
			}
		});
	}

	hide_or_show_form = () => {
		if(this.state.display_form)
			this.setState({display_form: false});
		else
			this.setState({display_form: true});
	}

	render(){
		if(this.state.display_form){
			return(
				<div className="row"  style={{"padding-right":"20px"}}>
					<div className="col-md-3" style={{"marginTop":"25px"}}>
						<button className="btn" onClick={this.hide_or_show_form}>Add Location Constraint</button>
					</div>
					<div className="col-md-9">
						<form className="form-horizontal" onSubmit={this.handleSubmit}>
							<div className="form-group">
							<label className="control-label" htmlFor="type">Add Constraint Over : </label>        
								<select required type="text" className="form-control" id="type" name="type" onChange={this.handleChange} value={this.state.type}>
								<option></option>
								<option>state</option>
								<option>district</option>
								<option>sub_division</option>
								<option>village</option>
							</select>		
							</div>
							{this.state.type === "state"&&
								<Select
									isMulti
									name="segment"
									options={ReactSession.get("states")}
									className="basic-multi-select"
									classNamePrefix="select"
									onChange ={this.handleSegmentChange}
								/>
							}
							{this.state.type === "district"&&
								<Select
									isMulti
									name="segment"
									options={ReactSession.get("districts")}
									className="basic-multi-select"
									classNamePrefix="select"
									onChange ={this.handleSegmentChange}
								/>
							}
							{this.state.type === "sub_division"&&
								<Select
									isMulti
									name="segment"
									options={ReactSession.get("sub_divisions")}
									className="basic-multi-select"
									classNamePrefix="select"
									onChange ={this.handleSegmentChange}
								/>
							}
							{this.state.type === "village"&&
								"village"
							}
							<div className="form-group" style={{"marginTop":"10px"}}>        
								<button type="submit" className="btn btn-default" style={{"float":"right"}}>Add Constraint</button>
							</div>
						</form>
					</div>
				</div>
			);
		}
		else{
			return(
				<div className="row">
					<div className="col-md-3" style={{"marginTop":"25px"}}>
						<button className="btn" onClick={this.hide_or_show_form}>Add Location Constraint</button>
					</div>
				</div>
			);
		}
	}
}

export default SchemeSegForm;