import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import moment from 'moment';

class PartnerTransactions extends Component{
	constructor(props) {
		super(props);

		this.state = {
			user_id: props.match.params.user_id,
			partner_transactions: [],
			logged_in: false,
			username: '',
			show_credit: false,
			show_debit: false,
		}

		if(typeof ReactSession.get("username") !== 'undefined'){
			this.state.logged_in = true;
			this.state.username = ReactSession.get("username");
		}
		this.fetch_partner_transactions = this.fetch_partner_transactions.bind(this);
		this.generate_partner_transactions_list = this.generate_partner_transactions_list.bind(this);
		this.hide_or_show_credit = this.hide_or_show_credit.bind(this);
		this.hide_or_show_debit = this.hide_or_show_debit.bind(this);
		this.hide_or_show_all = this.hide_or_show_all.bind(this);
        this.count_transactions = this.count_transactions.bind(this);
	}

	componentDidMount(){
		if(this.state.logged_in)
			this.fetch_partner_transactions()
	}

	fetch_partner_transactions = () => {
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		axios.get(global.config.apiGateway.URL+"/admin/users/"+this.state.user_id+"/transactions",
		{ 
			headers: { Authorization: access_token }
		})
		.then((response) => {
			var data = response.data;
			this.setState({partner_transactions:data});
		})
		.catch((error)=>{
			console.error(String(error));
		});	
	}

	generate_partner_transactions_list = () => {
		var partner_transactions_list = ""
		if(this.state.partner_transactions.length){
			partner_transactions_list = this.state.partner_transactions.map((transaction) =>{
				if((this.state.show_credit&&transaction.type===1)
					|| (!this.state.show_debit && !this.state.show_credit)
					|| (this.state.show_debit && transaction.type===2)){
					return(
						<div className="col-md-12 row list-group-item" key={transaction.id} style={{"background":(transaction.type===1)?"#a2ebb5":"#ffa991"}}>
							<div className="row">
								<div className="col-md-6">
										Transaction ID : {transaction.id}
								</div>
								{transaction.paid_on&&
									<div className="col md-6">
										{(transaction.paid_on) ? 'Created On : '+moment(transaction.paid_on).local().format("LLL") : ''}
									</div>
								}
							</div>
							<div className="row">
								<div className="col-md-3">
										Entity Type : {transaction.cause}
								</div>
								<div className="col-md-3">
										{(transaction.entity) ? 'Entity Name : '+transaction.entity : ''}
								</div>
								<div className="col-md-3">
										Transaction Amount : ₹ <strong>{transaction.amount}</strong>
								</div>
							</div>
						</div>
					);
				}
				else{
					return null;
				}
			});
		}
		else{
			partner_transactions_list = <div>
							<h3>No Transactions to show</h3>
						</div>;
		}
		return partner_transactions_list;
	}

    count_transactions = () => {
		var credit = 0;
		var all = 0;
		var debit = 0;
		this.state.partner_transactions.map((transaction) =>{
			if(transaction.type===1){
				credit++;
			}
			else{
                debit++;
            }
			all++;
			return null;
		});
		return {
			all: all,
			credit: credit,
			debit: debit
		};
	}

	hide_or_show_debit = () => {
		this.setState({show_debit: !this.state.show_debit, show_credit: false});
	}

	hide_or_show_credit = () => {
		this.setState({show_credit: !this.state.show_credit, show_debit: false});
	}

	hide_or_show_all = () => {
		this.setState({
			show_debit: false,
			show_credit: false
		});
	}

	render(){
		if(!this.state.logged_in){
			return(
				<div>
					<h5>Not logged in !</h5>
				</div>
			);
		}
		var partner_transactions_list = this.generate_partner_transactions_list();
        const {all, debit, credit} = this.count_transactions();
		return(
			<div className="row">
				<div className="col-md-12 page-header text-center">
					{this.state.user_id
						? <h2><small><Link to={"/partner_profile/"+this.state.user_id}>GP ID - {this.state.user_id}</Link></small>Transactions</h2>
						: <h2>Transactions</h2>
					}	
				</div>
				<div className="col-md-12" style={{"marginBottom":"10px"}}>
					<div className="col-md-4" style={{"display":"flex"}}>
						<h5>All Transactions : {all}</h5>
						<button className="btn btn-xs" onClick={this.hide_or_show_all} style={{"marginLeft":"5px"}}>Show All</button>
					</div>
					<div className="col-md-4" style={{"display":"flex"}}>
						<h5>Debit Transactions : {debit}</h5>
						<button className="btn btn-xs" onClick={this.hide_or_show_debit} style={{"marginLeft":"5px"}}>Show Debit</button>
					</div>
					<div className="col-md-4" style={{"display":"flex"}}>
						<h5>Credit Transactions : {credit}</h5>
						<button className="btn btn-xs" onClick={this.hide_or_show_credit} style={{"marginLeft":"5px"}}>Show Credit</button>
					</div>
				</div>
				{partner_transactions_list}
			</div>
		);
	}
}

export default PartnerTransactions;
