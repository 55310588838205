import React from "react";
import "./addressProof.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline.js";
function AddressProof(props) {
  return (
    <div className="kycCard">
      <div className="subStatus">
        Status:{" "}
        <span>
          {props.isApprovedAadhar ||
          props.isApprovedDl ||
          props.isApprovedVoterId
            ? "Done"
            : "Not Done"}
        </span>
      </div>
      <div className="kycCard_row1">
        <h4 style={{ margin: "0px", alignSelf: "center" }}>Aadhar Number</h4>
        <input placeholder="Not Provided" value={props.aadharNumber} readOnly />
        {props.isApprovedAadhar && (
          <span className="approved_text">
            Approved
            <CheckCircleOutlineIcon
              style={{ marginLeft: "6px", fontSize: "20px" }}
            />
          </span>
        )}
        {!props.isApprovedAadhar && (
          <span className="approved_text">Pending</span>
        )}
      </div>
      <div className="kycCard_row2">
        <h4>Driving Licence</h4>
        {!props.isApprovedAadhar && props.haveDl && (
          <div className="kycCard_imageSubgrid">
            <a href={props.driverLicenceFront}>
              <img
                src={props.driverLicenceFront}
                title="Front Photo"
                alt="User's Driver Licence frontside"
              />
            </a>
            <a href={props.driverLicenceBack}>
              <img
                src={props.driverLicenceBack}
                title="Back Photo"
                alt="User's Driver Licence backside"
              />
            </a>
          </div>
        )}
        {!props.isApprovedAadhar &&
          props.haveDl &&
          props.isApprovedDl === null && (
            <div className="address_approveControls">
              <button
                onClick={() => props.approveDoc(5, props.isApprovedDl)}
                className="button_approve"
              >
                Approve
              </button>
              <button
                onClick={() => props.rejectDoc(5, props.isApprovedDl)}
                className="button_reject"
              >
                Reject
              </button>
            </div>
          )}
        {!props.isApprovedAadhar && props.haveDl && props.isApprovedDl && (
          <div>
            <span className="approved_text">
              Approved{" "}
              <CheckCircleOutlineIcon
                style={{ fontSize: "20px", marginLeft: "6px" }}
              />
            </span>
          </div>
        )}
        {!props.isApprovedAadhar &&
          props.haveDl &&
          props.isApprovedDl === false && (
            <div>
              <span className="approved_text">Rejected</span>
            </div>
          )}
      </div>
      <div className="kycCard_row2">
        <h4>Voter ID</h4>
        {!props.isApprovedAadhar && props.haveVoterId && (
          <div className="kycCard_imageSubgrid">
            <a href={props?.voterIdFront}>
              <img
                src={props?.voterIdFront}
                title="Front Photo"
                alt="User's Voter ID frontside"
              />
            </a>
            <a href={props?.voterIdBack}>
              <img
                src={props.voterIdBack}
                title="Back Photo"
                alt="User's Voter ID backside"
              />
            </a>
          </div>
        )}
        {!props.isApprovedAadhar &&
          props.isApprovedVoterId === null &&
          props.haveVoterId && (
            <div className="address_approveControls">
              <button
                onClick={() => {
                  props.approveDoc(6, props.isApprovedVoterId);
                }}
                className="button_approve"
              >
                Approve
              </button>
              <button
                onClick={() => {
                  props.rejectDoc(6, props.isApprovedVoterId);
                }}
                className="button_reject"
              >
                Reject
              </button>
            </div>
          )}
        {!props.isApprovedAadhar &&
          props.haveVoterId &&
          props.isApprovedVoterId && (
            <div>
              <span className="approved_text">
                Approved{" "}
                <CheckCircleOutlineIcon
                  style={{ fontSize: "20px", marginLeft: "6px" }}
                />
              </span>
            </div>
          )}
        {!props.isApprovedAadhar &&
          props.haveVoterId &&
          props.isApprovedVoterId === false && (
            <div>
              <span className="approved_text">Rejected</span>
            </div>
          )}
      </div>
    </div>
  );
}

export default AddressProof;
